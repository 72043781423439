<template>
  <div class="container">
    <div class="container mt-3" v-if="eventData">
      <div class="bg" :style="`background: url(${getIpfsLink(eventData.event_displayCid)});`"></div>
      <div class="content_wrapper">
        <div class="row">

      <div class="col-lg-6 order-last order-lg-first"><img :src="getIpfsLink(eventData.event_displayCid)" class="d-block img-fluid mx-auto"></div>
      <div class="col-lg-6 order-first order-lg-last ps-lg-4 d-flex flex-column align-items-start pb-4 pt-lg-4 pt-0">
        <div class="badge">EVENT CHECKING FOR</div>
        <h3 class="mb-0 mt-2"><strong>{{ eventData.event_name }}</strong></h3>
        <div class="date">{{ eventData.event_date }}</div>
        <div class="scan_field d-flex w-100 mt-4 mb-4">
          <div class="w-50 position-relative">
            <font-awesome-icon icon="fa-solid fa-qrcode" @click="readQR()" />
            <input type="text" name="nftref" class="form-control qr-input" placeholder="Enter NFT id" v-model="nftREF">
          </div>
          <div class="wrap-btn w-50 d-flex justify-content-start">
            <button class="btn btn-primary ms-0 ms-lg-3" @click.prevent="generateQR()">GENERATE</button>
            <button class="btn btn-danger ms-3" @click.prevent="cancelWainting()">CLEAR</button>
          </div>
        </div>
        
        <div :class="{error: haveError}" v-show="responseMessage!=''" class="w-100 response text-center mt-3 mb-3">
          <div class="wrap">{{responseMessage}}</div>
        </div>

        <div class="wrap-detail w-100">
          <div class="loadingText" v-if="isLoading">
            Loading ticket detail...
          </div>
          <div class="h-100" v-else>
            <div class="d-flex w-100 h-100 align-items-center justify-content-center" v-if="!ticketData && !scanning">Enter ticket id or scan QR Code</div>
            <div class="ticketInfo mb-3" v-if="ticketData">
              <div class="ticket_type">{{ ticketData.ticket_name }}</div>
              <div class="ticket_owner">Owner: {{ ticketData.ticket_owner }}</div>
              <div class="ticket_price">Paid: <strong>{{ numberWithSpaces(base10ToDecimal(ticketData.ticket_price, 8)) }} SIGN</strong> - Ticket used: <strong>{{ ticketData.ticket_amountUsedInit }} / {{ticketData.ticket_maxUse}}</strong></div>
            
              <div class="used_already mt-4" v-if="ticketData.ticket_amountUsedInit == ticketData.ticket_maxUse">
                TICKET ALREADY USED
              </div>
            </div>
            
            <div id="ticket_history" v-if="ticketHistory.length">
              <h4 @click="historyOpen=!historyOpen" :class="{'open':historyOpen}">See ticket history <font-awesome-icon icon="fa-solid fa-chevron-up" /></h4>
              <transition name="fade">
                <ul v-if="historyOpen">
                  <li v-for="ticket in ticketHistory" :key="ticket.id">
                    <span class="date me-2">{{setDateTime(ticket.usage_date)}}</span>
                    <span class="user me-2">by: {{shortTxAddr(ticket.user)}}</span>
                    <span class="usage_num me-2">Usage #{{ticket.usage_num}}</span>
                    <a :href="getExplorerUrl()+'/tx/'+ticket.id" target="_blank" class="see_on_waves">Detail</a>
                  </li>
                </ul>
              </transition>
            </div>
            <div class="switchCam" v-if="scanning && (cameras && cameras.length > 1)">
              {{currentCam}} <font-awesome-icon icon="fa-solid fa-chevron-right" @click="switchCam()"/>
            </div>

            <video id="qrScan" :class="{'scanning': scanning}" class="h-100" autoplay disablepictureinpicture playsinline></video>
            <QRCodeVue3
                v-if="showQR && !confirmed"
                :value="qrURL"
                :width="300"
                :height="300"
                :qrOptions="{ typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' }"
                :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                :dotsOptions="{ type: 'square', color: '#05004d' }"
                :cornersSquareOptions="{ type: 'square', color: '#0e013c' }"
              />
              <div class="links mt-4 d-flex justify-content-center" v-if="showQR && !confirmed">
                
                <a class="link mention me-4" :href="qrURL" target="_blank"><font-awesome-icon icon="fa-solid fa-link" /> Open</a> 
                <div class="link mention pointer position-relative">
                  <div class="copied"></div>
                  <div @click="copyString(qrURL, $event)">
                    <font-awesome-icon icon="fa-solid fa-link" /> Copy
                  </div>
                </div>
              </div>
              <div v-if="isWaitingConfirmation && !confirmed" class="waiting_confirmation loadingText">Waiting confirmation...</div>
              <button v-if="isWaitingConfirmation && !confirmed" class="btn btn-danger sp" @click="cancelWainting()">CANCEL</button>

              <font-awesome-icon icon="fa-solid fa-check-circle" v-if="confirmed"/>
              <div v-if="confirmed" class="confirmed">ACCESS CONFIRMED</div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Global from '@/mixins/global.js';
import encodeUrl from 'encodeurl';
import QRCodeVue3 from "qrcode-vue3";
import QrScanner from 'qr-scanner';
let initialState = {
      nftREF: "",
      eventData: null,
      ticketData: null,
      ticketHistory: [],
      nftId: null,
      showQR: false,
      qrURL: null,
      haveError: false,
      responseMessage: '',
      isLoading: false,
      isWaitingConfirmation: false,
      confirmed: false,
      qrScanner: null,
      scanning: false,
      cameras: null,
      historyOpen: false
    }

export default {
  name: 'Event-checkin-page',
  mixins: [Global],
  data: function(){
    return {
      currentCam: null,
      ...initialState
    }
  },
  components: {
    QRCodeVue3
  },
  async created(){
    await this.getEventById(this.$route.params.eventId)
    //this.initScanner()
  },
  mounted(){
    
  }, 
  beforeUnmount(){
    this.clearScanner()
  },
  methods:{
    onDecode: function(){
      console.log("toto")
    },
    initScanner: async function(){
      let _this = this
      let videoElm = document.getElementById("qrScan")
        this.qrScanner = new QrScanner(  
          videoElm,
          function(result){
            console.log('decoded qr code:', result);
            _this.nftREF = result.data
            _this.qrScanner.stop()
            _this.scanning = false
            _this.generateQR()
          },
          {
            highlightScanRegion: true,
            highlightCodeOutline: true,
          },
        );

      this.hasCam = await QrScanner.hasCamera();
      window.scanner = this.qrScanner
      if(this.hasCam){
        _this.cameras = await QrScanner.listCameras();
        if(!this.$isMobile()){
          _this.currentCam = 0
          await _this.qrScanner.setCamera(_this.cameras[_this.currentCam].id)
        }else{
          await _this.qrScanner.setCamera("environment")
        }
      }
      return
    },
    readQR: async function(){
      this.nftREF = ""
      this.ticketData = null
      this.isWaitingConfirmation = false
      this.showQR=false
      await this.initScanner()
      let _this = this
      this.scanning = true

      try{
          await _this.qrScanner.start()
          _this.hasCamera = true;
        }catch(error){
          console.log(error);
          _this.hasCamera = false;
        }
        
    },  
    switchCam: async function(){
      this.currentCam = this.currentCam+1 < this.cameras.length ? this.currentCam+1 : 0
      this.qrScanner.setCamera(this.cameras[this.currentCam].id);
    },  
    closeNewTicketPopin: function(){
      this.showNewTicketPopin = false
    },
    getEventById: async function(eventId){
      let regex = encodeUrl("^event_(.*)_"+eventId+"_(.*)")
      try{
        let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
        getList = await getList.json()
        let orderedList = this.getOrderedEvents(getList)
        this.eventData = {...orderedList[0]}
        
      }catch(err){
        this.haveError = true
        this.responseMessage = err
      }
    },
    getTicketByTicketId: async function(ticketId, owner){
      let regex = encodeUrl("^ticket_(.*)_"+this.$route.params.eventId+"_(.*)_"+ticketId)
      try{
        let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
        getList = await getList.json()
        let orderedList = this.getOrderedTickets(getList)
        if(orderedList[0]){
          orderedList[0].ticket_owner = owner
          this.ticketData = {...orderedList[0]}
        }else{
          this.haveError = true
          this.responseMessage = "Ticket don't match the event"
        }
        

      }catch(err){
        this.haveError = true
        this.responseMessage = err
      }
    },
    getTicketOwner: async function(nftId){
      
        let height = await this.getCurrentHeight() - 1
        let getTicketOwner = await fetch(`${this.$store.state.config.nodeURL}/assets/${nftId}/distribution/${height}/limit/1`)
        getTicketOwner = await getTicketOwner.json()
        return getTicketOwner.lastItem
    },
    getAmountUsed: async function(){
      let regex = encodeUrl("^ticket_amountUsed_"+this.$route.params.eventId+"_(.*)_"+this.ticketData.txid+"_"+this.nftId)
      let amountUsed = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      amountUsed = await amountUsed.json()  
      return amountUsed[0] ? amountUsed[0].value : 0
    },  
    listenConfirmation: async function(ticketId){
      let _this = this
      if(this.isWaitingConfirmation){
        let used = await this.getAmountUsed()
        if(used <= this.ticketData.ticket_maxUse && used != this.ticketData.ticket_amountUsedInit){
          this.isWaitingConfirmation = false
          this.confirmed = true
          this.ticketData.ticket_amountUsedInit+=1
          this.getTicketHistory()
        }else{
          setTimeout(function(){
            if(_this.ticketData){
              _this.listenConfirmation(ticketId)
            }
          }, 2000)
        }
      }

    },
    clearScanner: function(){
      if(this.qrScanner){
        this.qrScanner.destroy()
        this.qrScanner = null
      }
    },
    cancelWainting: async function(){
      let nftREF = this.nftREF
      this.clearScanner()
      Object.assign(this.$data, { nftREF, ...initialState });
      await this.getEventById(this.$route.params.eventId)
    },
    getTicketHistory: async function(){
      let regex = encodeUrl("^ticket_historyUsed_"+this.$route.params.eventId+"_(.*)_"+this.ticketData.txid+"_"+this.nftId+"_(.*)")
      let history = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      history = await history.json() 
      let historyArrObj = []
      for(let data in history){
        let dataArr = history[data].value.split("_")
        let temp = {
          id: dataArr[0],
          user: dataArr[1],
          usage_date: dataArr[2],
          usage_num: dataArr[3]
        }
        historyArrObj.push(temp)
      }
      this.ticketHistory = historyArrObj
    },
    generateQR: async function(){
      this.clearScanner()
      this.scanning = false
      this.qrScanner = null
      this.isWaitingConfirmation = false
      this.confirmed = false
      this.isLoading = true
      this.haveError = false
      this.responseMessage = ''
      let ticketId
      try{
        let getNFTidByAssetName = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${this.nftREF}_assetId`)
        getNFTidByAssetName = await getNFTidByAssetName.json()
        if(getNFTidByAssetName[0]){
          [ticketId, this.nftId] = getNFTidByAssetName[0].value.split("_")
          let owner = await this.getTicketOwner(this.nftId)
          await this.getTicketByTicketId(ticketId, owner)
          this.ticketData.ticket_amountUsedInit = await this.getAmountUsed()
          this.qrURL = this.$store.state.config.baseURL+"/confirmation/"+this.nftId+"/"+this.nftREF      
        }else{
          this.haveError = true
          this.responseMessage = 'Ticket not found'
          return
        }
      }catch(err){
        this.haveError = true
        this.responseMessage = err
      }
      this.isLoading = false
      this.getTicketHistory()

      if(this.ticketData.ticket_maxUse == this.ticketData.ticket_amountUsedInit){
        this.showQR = false
      }else{
        this.showQR = true
        this.isWaitingConfirmation = true
        
        this.listenConfirmation(ticketId)
      }
      
    }
  }

}
</script>

<style lang="scss" scoped>
.bg{
    filter: brightness(15%);
  }
.links{
  .link{
    text-decoration: underline;
    color: white;
    cursor: pointer;
  }
}
.fa-circle-check{
  font-size: 200px;
    margin-bottom: 20px;
    color: #3bedef;
}
.confirmed{
  font-size: 30px;
  color: #3bedef;
}
#qrScan{
  width: 100%;
  opacity: 0;
  height: 0!important;
  width: 100%;
  
  object-fit: cover;
  margin-top: 10px;
  &.scanning{
    opacity: 1;
    height: 90% !important;
  }
}
.fa-qrcode{
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  color: black;
  font-size: 20px;
}
.qr-input{
  padding-right: 30px;
}
.fa-chevron-right{
  cursor: pointer;
  font-size: 18px;
}
.switchCam{
  text-align: right;
}
.badge{
  background-color: #ffac0c;
  border-radius: 10px;
  color: black;
  padding: 10px 20px;
  font-weight: 500;
  letter-spacing: initial;
  font-size: 18px;
}
.ticket_type{
  font-size: 24px;
  font-weight: 600;
}
.ticket_price{
  strong{
    font-weight: bold;
  }
}
.wrap-detail{
  border-radius: 10px;
  border:solid 1px #ccc;
  min-height: 100px;
  flex:1;
  padding: 20px;
  text-align: center;
  position: relative;
  font-size: 16px;
}
.btn-danger.sp{
  position: absolute;
  z-index: 8;
  background-color: black!important;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}
.used_already{
  font-size: 23px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ef483b;
}
#ticket_history{
  li{
    font-size: 15px;
    letter-spacing: initial;
    a{
      color: white;
    }
  }
  h4{
    font-size: 15px;
    letter-spacing: 0.5px;
    padding-bottom: 3px;
    border-bottom: 1px solid white;
    display: inline-block;
    margin-bottom: 15px;
    cursor: pointer;
    &.open{
      .fa-chevron-up{
        transform: rotate(180deg);
      }
    }
  }
}
@media(max-width:992px){
  .scan_field{
    flex-direction: column;
    .w-50{
      width: 100%!important;
      margin-bottom: 15px;
    }
    .w-25{
      width: 50%!important;
    }
    .wrap-btn{
      justify-content: center!important;
    }
  }
}
</style>
