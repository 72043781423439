<template>
  <div class="page">
    <div class="container text-center">
      <div class="row">
        <div class="col-12 position-relative">
          <div id="content_wrapper">
            <div class="wrap">
              <h3 class="mb-4">DECENTRALIZED<br/>NFT TICKETING<br/>PLATFORM</h3>
              <p>Create and sell NFT tickets for any types of event such as Music, Gaming, Conventions, Sport and way more...</p>
              <router-link to="/events" class="cta mt-3">SEE ALL EVENTS</router-link>
            </div>
            <div class="container organizer_attendee">
              <div class="row">
                <div class="col-lg-6 d-flex justify-content-start pe-lg-5 align-items-center ">
                  <img src="@/assets/images/home-organizers.png" class="me-xl-5"/>
                  <div class="text">
                    <h2>For Organizers</h2>
                    <p>Create events and add tickets per tier to them. Define reselling condition such as temporary locking period. Get royalties secondary market.</p>
                    <a href="#" class="cta mt-2" @click="chooseSigningMethod()">REGISTER NOW</a>
                  </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-start ps-lg-5 mt-md-0 mt-5 align-items-center ">
                  <img src="@/assets/images/home-attendees.png" class="me-xl-5"/>
                  <div class="text">
                    <h2>For Attendees</h2>
                    <p>Buy events tickets at fair price and own them as NFT collectible that you can use to access the event, transfer or resell on the secondary market.</p>
                    <router-link to="/events" class="cta mt-2">BROWSE EVENTS</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <video autoplay muted loop id="bg-video">
            <source src="@/assets/images/bg.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Global from '@/mixins/global.js';
export default {
  name: 'Home-page', 
  mixins:[Global],
  mounted(){
    window.addEventListener('resize', this.positionContent)
    this.positionContent()
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.positionContent)
  },
  watch:{
    '$store.state.user.userReady': function(){
      if(this.$store.state.user.isLogged){
        this.goToAccount()
      }
    }
  },
  methods: {
    chooseSigningMethod: function(){
      this.$store.state.loginRedirect=true
      console.log("this.$store.state.user.isLogged")
      console.log(this.$store.state.user.isLogged)
      if(this.$store.state.user.isLogged){
        console.log("User already logged in")
        this.goToAccount()
      }else{
        this.$store.state.signingChoice.show = true
      }
    },
    positionContent: function(){
      let headerH = document.querySelector("header").clientHeight 
      let contentH = document.getElementById("content_wrapper").querySelector(".wrap").clientHeight 
      contentH += parseInt(window.getComputedStyle(document.getElementById("content_wrapper").querySelector(".wrap")).getPropertyValue('margin-top'));
      let headerContentH = headerH+contentH
      let marginTop = document.documentElement.clientHeight - headerContentH
      if(marginTop < 150){
        document.querySelector(".organizer_attendee").style.marginTop = "150px"
      }else{
        document.querySelector(".organizer_attendee").style.marginTop = marginTop+"px"
      }
      
      console.log(marginTop)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrap{
  width: 100%;
  text-align: left;
  color: white;
  margin-top: 80px;

  h3{
    text-align: left!important;
    letter-spacing: 14px;
    font-size: 40px;
    font-weight: 700;
  }
  p{
    font-size: 24px;
    font-weight: 300;
    max-width: 480px;
  }
}
#bg-video{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: brightness(25%);
}
#content_wrapper{
  z-index: 2;
  position: relative;
  width: 100%;
  .organizer_attendee{
    margin-top: 180px;
    color: white;
    text-align: left!important;
    position: relative;
    .text{
      h2{
        font-size: 30px;
        font-weight: 400;
      }
      p{
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

@media(max-width: 1200px){
  #content_wrapper{
  .organizer_attendee{
    .col-lg-6{
      flex-direction: column;
      text-align: center;
      img{
        max-width: max-content;
        margin: auto;
      }
    }
    margin-top: 60px!important;    
  }
}
}

@media(max-width: 768px){
  #content_wrapper{
  .organizer_attendee{
    .col-lg-6{
      flex-direction: column;
      text-align: center;
      img{
        max-width: max-content;
        margin: auto;
      }
    }
    margin-top: 60px!important;    
    .text{
      h2{
        font-size: 25px;
      }
    }
  }
}
}
</style>
