<template>
  <div class="page" id="about">
    <div class="container">
      <div class="col-lg-8 offset-lg-2">
        <h3 class="mt-4 mb-4">ABOUT</h3>
        <p>SIGN NFT Ticketing for events is created by Signature Chain developer exclusively as his participation to the <strong>DoraHacks x Waves Hackathon</strong></p>
        <p>The app allow anyone to create an Organizer account to create new events. For each event, organizers can add several ticket tiers for sale.</p>
        <p>The app is running with SIGN token for the demo. Organizer can start checking-in buyers tickets at anytime to validate their access to the event.</p>
        <p>Tickets can be reselled on the secondary market (marketplace) under the conditions set by the organizer, any resell would bring x% royalties to the organizer (defined by himself) and 2% service fee to the platform as an example.</p>
        <p>No proper business model are defined yet for the platfom and service fee are just an example</p>
        <p>Some moderation may be required to avoid abuse in potential production version as always when dealing with photos/videos</p>
        <p>Multi-usage ticket already used partially cannot be transfered to access event but can be traded as collectible.</p>
        <p>This is an early MVP for the hackathon and is not ready for production, Smart Contract aren't audited and the MVP would need several deep optimizations for scalability to meet production need. The Signature Chain team may or may not push it further in that direction after the hackathon.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About-page'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#about{
  color: white;
}
</style>
