
<template>
  <span class="usdPrice mr-2" :class="{white: color =='white', grey: color =='grey'}" v-show="show"> ({{ usdPrice != '' ? usdPrice : '...' }})</span>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  mixins: [globalMixin],
  props: ['ticket', 'color', 'secondary', 'offer'],
  data: function(){
    return {
      usdPrice: '',
      signPrice: '',
      assetsPrice: '',
      show: false
    }
  },
  created(){
    this.getPriceInUsd()
  },
  methods: {
    getPriceInUsd: async function(){

      let result, price
      if(this.secondary){
        price = this.secondary.sell_price
      }else if(this.offer){  
        price = this.offer.price * Math.pow(10,8)
      }else{
        price = this.ticket.ticket_price 
      }
      this.show=true
      result = price / Math.pow(10, 8) * this.$store.state.priceInUSDN.signPriceInUsd

      if(result){
        this.usdPrice = result.toFixed(2)+"$"
        
      }
    },
  },
  watch:{
    'secondary.saleInfo':{
      deep:true,
      handler() {
        this.getPriceInUsd();
      }
    },
    '$store.state.priceInUSDN':{
      deep:true,
      handler() {
        this.getPriceInUsd();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .usdPrice{
    font-weight: 400!important;
    color: #ca26c0!important;
    font-size: 13px;
    &.white{
      color: white!important;
      opacity: 0.8;
    }
    &.grey{
      color: rgb(79, 79, 79)!important;
      opacity: 0.8;
    }
  }
</style>