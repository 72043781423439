<template>
  <div id="msg-layer" v-show="$store.state.alert">
    <div class="wrapper">
      <img src="@/assets/images/loader.svg" class="d-block loader"/>
      <div class="message text-center" v-html="$store.state.alertMessage"></div>
      <div class="progress w-100 mt-2">
        <div class="progress-txt w-100 text-center"></div>
        <div class="wrap_progress_bar w-100">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
export default {
  name: "msg-layer",
  data: function(){
    return {}
  }
}
</script>
<style lang="scss" scoped>
#msg-layer{
  position: fixed;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 38, 58, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top:0;
  letter-spacing: 0.5px;
  .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 10px 20px;
    background-color: white;
    flex-direction: column;
    border-radius: 10px;
    .loader{
      max-width: 50px;
    }
    .message{
      color: #05180b;
    }
  }
}
#msg-layer{
  .progress{
    height: 0px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    &.show{
      height: 35px !important;
    }
  }
  .progress-txt{
    color: #57137b;
    top: 15px !important;
  }
}
</style>