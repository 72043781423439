<template>

  <div class="container events">
    <div class="loadingText" v-if="isLoadingEvents">
      Loading events list...
    </div>
    <div class="text-center mt-4" v-if="!allEvents.length && !isLoadingEvents">
      No events found.
    </div>
    <div class="row event" v-else  v-for="(event, key) in allEvents" :key="event.txid" :data-index="key">
      <EventItemFront :event="event" :keyIndex="key" />
    </div>
  </div>

</template>

<script>
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
import EventItemFront from '@/components/elements/EventItemFront.vue' 
export default {
  name: 'events-list-page-front',
  mixins: [Global],
  data: function(){
    return {
      allEvents: [],
      isLoadingEvents: false,
      imgix: undefined
    }
  },
  created(){
    this.getAllEvents()
  },
  components: {
    EventItemFront
  },
  methods:{
    getAllEvents: async function(address = ''){
      this.isLoadingEvents = true
      let regex = encodeUrl("^event_(.*)") 
      if(address!=''){
        regex = encodeUrl("^event_(.*)_"+address)
      }
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedEvents(getList)

      // get all display as imgx
      let imgixData =[]
      let _this = this
      orderedList.forEach(function(e){
        imgixData.push({id: e.txid, url: _this.getIpfsLink(e.event_displayCid)})
      })
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imgix", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(imgixData)
          });
      const imgix = await response.json();
      this.imgix = imgix.res

      this.allEvents = orderedList
      this.isLoadingEvents = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
