<template>
  <div class="page" id="allEvents">
    <div class="container">
      <div class="row">
        <div class="bg"></div>
        <div class="content_wrapper">
          <h3 class="mt-4 text-left">ALL EVENTS</h3>
          <app-events-list-front />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventsListFront from '@/components/elements/EventsListFront.vue' 
export default {
  name: 'Events-page',
  components:{
    "app-events-list-front": EventsListFront
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  h3{
    margin-bottom: 40px;
  }
  .bg{
    background: url("@/assets/images/bg-blue.jpg");
  }

</style>
