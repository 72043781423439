<template>

  <div class="w-100 ticket">
    
    <div class="title_desc">
      <h4>{{ticket.ticket_name}}</h4>
      <div class="desc">{{ ticket.ticket_desc }}</div>
      <div class="mention warning" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="'Resell allowed after: '+setDate(ticket.ticket_resellLimitation)" v-if="ticket.ticket_resellLimitation>0 && ticket.ticket_resellLimitation >  new Date().getTime()"><font-awesome-icon icon="fa-solid fa-triangle-exclamation" /> Resell limited</div>
    </div>
    
    <div class="tickets_details">
      <div class="tickets_sold">Sold: <span>{{ticket.ticket_totalIssued}} of {{ ticket.ticket_maxAvailable }}</span></div>
      <div class="tickets_price">Price: <span>{{ numberWithSpaces(base10ToDecimal(ticket.ticket_price, 8)) }} SIGN</span></div>
      <div class="tickets_use">Max use: <span>{{ ticket.ticket_maxUse }}</span></div>
    </div>

    <div class="actions">
      <button class="cta" @click.prevent="buyTicket()" :disabled="!ticket.ticket_onSale">Buy Ticket</button>
      <div class="mention" v-if="!ticket.ticket_onSale">Not available</div>      
    </div>

    <div :class="{error: haveError}" v-show="responseMessage!=''" class="response col-xl-10 mx-auto text-center mt-2 mb-2 p-2 flex-grow-1">
      <div class="wrap">{{responseMessage}}</div>
    </div>
    
  </div>
  
</template>

<script>
/* import encodeUrl from 'encodeurl'; */
import Global from '@/mixins/global.js';
import date from 'date-and-time'
export default {
  name: 'Manage-page-item-ticket',
  mixins: [Global],
  props:['ticket'],
  emits: ['getTicketsByEventId','updateEventTotalSold'],
  data: function(){
    return {
      haveError: false,
      responseMessage: ''
    }
  },
/*   computed: {
    getSignerInstance () {
      return this.$store.getters.getSignerInstance
    }
}, */
  methods: {
    setDate: function(timestamp){
      return date.format(new Date(timestamp),'YYYY/MM/DD HH:mm:ss')
    },
    buyTicket: async function(){
      console.log("processing buy...")
      this.haveError = false
      if(this.$store.state.user.isLogged){
        this.responseMessage = 'Processing your request, please wait...'
      }
      let _this = this
      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'
      
      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: "buyTicket",
            args:[
              { type:"string", value: this.ticket.txid },
              { type:"string", value: this.$route.params.eventId },
              { type:"string", value: this.ticket.user_addr }
            ]
        },
        payment: [
          { 
            assetId: this.$store.state.config.assetID, 
            amount: this.ticket.ticket_price 
          }
        ]
      }
      
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.haveError = true
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Ticket successfully bought!'
            setTimeout(function(){
              _this.responseMessage = ''
              _this.$emit('getTicketsByEventId', _this.$route.params.eventId);
              _this.$emit('updateEventTotalSold', _this.$route.params.eventId);
              // parent reload ticket list
            }, 3000)
          }
      })
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.ticket{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 15px;
  letter-spacing: initial;
  font-weight: 300;
  flex-wrap: wrap;
  .title_desc{
    width: 45%;
    padding-right: 10px;
    
    
    h4{
      margin-bottom: 5px;
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      
    }
  }
  .tickets_details{
    //width: 35%;
    span{
      font-weight: 500;
    }
    
  }
  .actions{
    //width: 20%;
    margin-left: auto;
  }
}
.actions{
  min-width: 120px;
  text-align: center;
  .delete{
    color: #8b8b8b;
    text-decoration: underline;
    font-size: 13px;
    margin-top: 3px;
    cursor: pointer;
    &:hover{
      text-decoration: none;
    }
  }
}

</style>
