<template>
  <div class="page popin" v-if="show">
    <font-awesome-icon icon="fa-solid fa-xmark" @click="closeCreatePopin()"/>
    <div id="createEvent">
      <h3 class="text-center">{{ getTitle() }}</h3>
      <form>
        <div class="mb-4">
          <label for="eventTitle" class="form-label" >Event name: <span class="mandatory">*</span></label>
          <input type="text" class="form-control" id="eventTitle" v-model="name" maxlength="300"/>
        </div>
        <div class="mb-4">
          <label for="eventDesc" class="form-label">Description: <span class="mandatory">*</span></label>
          <textarea class="form-control" id="eventDesc" v-model="description" maxlength="3000" row="5"></textarea>
        </div>
        <div class="mb-4">
          <label for="eventDate" class="form-label">Date: <span class="mandatory">*</span></label>
          <input type="text" class="form-control" id="eventDate" v-model="date"/>
        </div>
        <div class="mb-4">
          <div class="form-group display_wrap">
            <label for="displayFile">Event flyer: <span class="mandatory">*</span> <span data-bs-toggle="tooltip" :data-bs-placement="`This is the file that will be displayed in listing pages. Supported: ${$store.state.acceptedFiles.display.extImg}`"><i class="fas fa-question-circle"></i></span></label>
            <div class="wrap_file_field">
              <div class="wrap_file">
                <div :class="{error: display.dimensionError}" class="top mb-2 ms-1" v-html="display.label"></div>
                <input type="file" class="form-control" id="displayFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.display.accept" @change="fileDimension('display', $event)">
              </div>
              <div class="progression hide" v-if="!display.dimensionError"><div class="counter"></div></div>
            </div>
            
            <div class="mention mt-1 ms-1">(PNG/JPG/GIF 5Mb) </div>
          </div>
          <div v-if="display.isMP4" class="col-12 crawler_wrap">
            <div id="crawlerPreview" >
            <p><strong>You are using a video as flyer</strong>, this require a thumbnail for crawlers <span class="mandatory">*</span></p>
            <div class="wrap_file_field">
              <div class="wrap_file">
                <div :class="{error: crawler.dimensionError}" class="top" v-html="crawler.label"></div>
                <input type="file" class="form-control" id="crawlerFile" placeholder="Select a file" :accept="$store.state.acceptedFiles.crawler.accept" @change="fileDimensionCrawler('crawler', $event)">
              </div>
              <div class="progression hide"><div class="counter"></div></div>
            </div>
            <div class="mention">(100Kb max.)</div>
          </div>
        </div>
        </div>
        <div class="col-12 mb-3" v-show="mp4Alert">
          <div class="mp4Alert p-2 d-flex align-items-center">
            <i class="fas fa-exclamation-triangle mr-2"></i> <p class="mb-0">MP4 with height <strong>over 2500px may not play</strong> on every mobile devices.<br/>It is also recommended to <strong>use h264</strong> generic codec.</p>
          </div>  
        </div>
        <div class="mb-4">
          <div class="form-group">
            <label for="title">Tags (5 single words max, separated by comma ',')</label>
            <input type="texte" class="form-control" id="tags" placeholder="Tags" v-model="tags" >
            <div v-show="tagsValidate != ''" class="mt-1 error">{{tagsValidate}}</div>
            <ul class="tags" v-show="tagFormated.length > 0">
                <li v-for="(tag, key) in tagFormated" :key="key">{{ tag }}</li>
            </ul>
          </div>
        </div>
        <div class="form-check mb-3 mt-4">
          <input class="form-check-input" type="checkbox" value="" id="imtheowner" :checked="imtheowner" @click="imtheowner = !imtheowner">
          <label class="form-check-label" for="imtheowner">
            I acknowledge that the flyer and content of this post do not go against the platform terms. I certify that <strong>there is no copyright infringement</strong> associated to this flyer. <span class="mandatory">*</span>
          </label>
        </div>
        <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="w-100 mx-auto text-center mt-3">
          <div class="wrap">{{responseMessage}}</div>
        </div>
        <button type="submit" class="btn btn-primary d-block mx-auto"  @click.prevent="processAdd" >{{getTitle()}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import Global from '@/mixins/global.js';
let initialState = {
    name: "",
    description: "",
    date: "",
    tags: "",
    tagFormated: "",
    tagsValidate: "",
    imtheowner: false,
    responseMessage: '',
    haveError: false,
    display: {
      cid: "",
      maxsize: 5000000,
      maxsizeGif: 5000000,
      //maxsizevid: 50000000,
      label: "",
      dimensionError: false,
      imageToUpload: null,
      isMP4: false
    },
    crawler: {
      cid: "",
      maxsize: 110000,
      maxsizeGif: 0,
      label: "Select the crawler file",
      dimensionError: false,
      imageToUpload: null
    },
  }
export default {
  name: 'New-Event',
  mixins: [Global],
  props: ['show', 'closeCreatePopin', 'eventToEdit'],
  data: function(){
    return initialState
  },

  methods: {
    
    resetWindow: function (){
        document.getElementById("displayFile").value = ""
        if(document.getElementById("crawlerFile")){
          document.getElementById("crawlerFile").value = ""
        }
        Object.assign(this.$data, initialState);
    },
    processAdd: async function(){
      console.log("processing Add/Edit...")
      let _this = this
      this.checkMandatoryFields()
      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }
      await this.checkUploadFiles()

      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }

      let functionToCall = this.eventToEdit && this.eventToEdit.event_name ? "editEvent" : "addEvent"

      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: functionToCall,
            args:[
              { type:"string", value: this.name },
              { type:"string", value: this.description },
              { type:"string", value: this.tags },
              { type:"string", value: this.display.cid },
              { type:"string", value: this.crawler.cid },
              { type:"string", value: this.date },
          ]
        }
      }
      if(this.eventToEdit && this.eventToEdit.event_name){
        dataTx.call.args.unshift({ type:"string", value: this.eventToEdit.txid })
      }
      /* dataTx.payment = [{ assetId: _this.$store.state.config.assetID, amount: (_this.$store.state.certificationPrice * Math.pow(10,8)).toFixed(0) }]  */

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.mp4Alert = false
            this.resetWindow()
            this.responseMessage = 'Event successfully added!'
            setTimeout(function(){
              _this.responseMessage = ''
            }, 3000)
          }
      })

    },
    getTitle: function(){
      return this.eventToEdit && this.eventToEdit.event_name ? "EDIT EVENT" : "CREATE A NEW EVENT"
    }
  },
  beforeUnmount(){
    document.querySelector("body").style.overflowY = "auto"
  },
  watch: {
    show: function(show){
      if(show){
        if(this.eventToEdit && this.eventToEdit.event_name){
          this.name = this.eventToEdit.event_name
          this.description = this.eventToEdit.event_desc
          this.date = this.eventToEdit.event_date
          this.display.cid = this.eventToEdit.event_displayCid
          if(this.eventToEdit.event_crawlerCid){
            this.crawler.cid = this.eventToEdit.event_crawlerCid
          }
          this.tags = this.eventToEdit.event_tags
        }
        document.querySelector("body").style.overflowY = "hidden"
      }else{
        Object.assign(this.$data, initialState);
        document.querySelector("body").style.overflowY = "auto"
      }
    },  
    tags: function(){
      this.tags = this.tags.replace(/[^a-z0-9,]/gi,'')
      let tagList = this.tags.split(",")
      if(tagList.length > 5){
        this.tagsValidate = "5 words maximum separated by space"
        this.validationError = true
      }else{
        let tempList = []
        tagList.forEach(function(e){
          tempList.push(e)
        })
        var cleanTags = tempList.filter(function (el) {
          return el != "";
        });
        this.tagFormated = cleanTags
        this.tagsValidate = ""
        this.validationError =false
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.error{
  background-color: red;
  display: inline-block;
  padding: 2px 10px;
  border-radius:5px;
}
.tags{
  display: flex;
  margin-top: 10px;
  li{
    padding: 3px 10px;
    border-radius: 120px;
    background-color: #121212;
    font-size: 15px;
    letter-spacing: 0.5px;
    margin-right: 10px;
    line-height: 18px;
  }
}
.popin{
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 38, 58, 0.9);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  left: 0;
  padding: 30px 0;
  overflow: auto;
  #createEvent{
    width: 100%;
    max-width: 650px;
    padding: 20px;
  }
  .fa-xmark{
    font-size: 26px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor:pointer;
  }
}

</style>
