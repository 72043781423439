<template>
  <div v-if="!eventDetail.txid" class="text-center">
    Loading event data
  </div>
  <div v-else class="row event">
    <div class="flyer">
      
      <router-link :to="`/marketplace/ticket/${eventDetail.txid}/${ticketDetail.ticketId}/${ticketDetail.nftId}/${ticketDetail.issue_num}/${ticketDetail.max_issuable}`">
        <imageLoader 
        :source="eventDetail.event_displayCid ? getIpfsLink(eventDetail.event_displayCid) : emptySrc" 
        :imgix="imgix && imgix[0].url ? imgix[0].url : eventDetail.event_displayCid" 
        :altname="eventDetail.event_name" 
      />
      </router-link>
    </div>
    <div class="actions">
        <router-link :to="`/marketplace/ticket/${eventDetail.txid}/${ticketDetail.ticketId}/${ticketDetail.nftId}/${ticketDetail.issue_num}/${ticketDetail.max_issuable}`" class="cta">SEE TICKET DETAIL</router-link>
      </div>
    <div class="content">
      <div v-if="eventDetail.event_canceled">CANCELED <p>{{eventDetail.event_canceledReason}}</p></div>
      <h2>{{eventDetail.event_name}}</h2>
      <div class="date">{{ eventDetail.event_date }}</div>
      <div class="ticket_used" 
      :class="{'used': usedAmount!=0}" v-if="usedAmount!=undefined"
      >{{usedAmount!=0 ? 'COLLECTIBLE':'OPEN TICKET'}}</div>
      <div class="tickets_details">
        <!-- <Creator :user_addr="eventDetail.user_addr" /> -->
        <div class="tickets_sold" v-if="ticketDetail && ticketDetail.issue_num">#<span>{{ticketDetail.issue_num}} of {{ticketDetail.max_issuable}}</span></div>  
        <div class="tickets_price" v-if="secondaryData">Price: <span>{{ numberWithSpaces(secondaryData.sell_price / Math.pow(10, 8)) }} SIGN</span></div>
      </div>

    </div>
  </div>
</template>

<script>
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
import imageLoader from '@/components/elements/ImageItem.vue' 
/* import Creator from '@/components/admin/elements/EventItemCreator.vue'  */
export default {
  name: 'event-page-item-secondary',
  mixins: [Global],
  props:['ticket'],

  data: function(){
    return {
      eventDetail: {},
      ticketDetail: {},
      secondaryData: null,
      imgix: undefined,
      usedAmount: undefined
    }
  },
  created(){
    this.getEventById(this.ticket.eventId)
    this.getTicketSoldById(this.ticket.nftId)
    this.getSecondaryTicketData(this.ticket.eventId, this.ticket.nftId)
    this.setAmountUsed()
  },
  components:{
    /* Creator, */
    imageLoader
  },
  methods: {
    getEventById: async function(eventId){
      let regex = encodeUrl("^event_(.*)") 
      regex = encodeUrl("^event_(.*)_"+eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedEvents(getList)

      let imgixData =[]
      let _this = this
      orderedList.forEach(function(e){
        imgixData.push({id: e.txid, url: _this.getIpfsLink(e.event_displayCid)})
      })
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imgix", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(imgixData)
          });
      const imgix = await response.json();
      this.imgix = imgix.res

      this.eventDetail = {...orderedList[0]}
    },
    getTicketSoldById: async function(nftId){
      let regex = encodeUrl("^ticket_sold_(.*)_of_(.*)_"+this.ticket.eventId+"_(.*)_"+nftId) 
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getTicketSoldData(getList)
      this.ticketDetail = {...orderedList[0]}
    },
    getSecondaryTicketData: async function(ticketId, nftId){
      let regex = encodeUrl("^sale2nd_info_(.*)_"+ticketId+"_(.*)_"+nftId+"_OPEN")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_secondary}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedSecondaryTickets(getList)
      this.secondaryData = orderedList[0]
    },
    setAmountUsed: async function(){
      let regex = encodeUrl("^ticket_amountUsed_"+this.ticket.eventId+"_(.*)_"+this.ticket.ticketId+"_"+this.ticket.nftId)
      let amountUsed = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      amountUsed = await amountUsed.json()  
      this.usedAmount = amountUsed[0] ? amountUsed[0].value : 0
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.event {
  height: 100%!important;
  @media(max-width:768px){
    height: auto!important;
    margin-bottom: 5px!important;
  }
  .flyer{
    max-height: 450px!important;
    border-radius: 20px 20px 0 0!important;
    @media(max-width: 992px){
      max-height: 350px!important;
    }
  }
  .content{
    padding: 10px 20px 10px 20px!important;
    align-items: center!important;
    h2{
      font-size: 22px!important;
      font-weight: 500!important;
      margin-bottom: 3px;
      margin-top: 0px;
      text-align: center!important;
    }
  }
}

.tickets_details{
  display: flex!important;
  justify-content: space-between!important;
  .tickets_price{
    margin-right: 0!important;
    font-size: 15px!important;
  }
  .tickets_sold{
    margin-right: 0!important;
    font-size: 15px!important;
  }
  
}
.date{
  font-size: 15px;
  color: #6b6b6b;
  font-weight: 300!important;
  margin-bottom: 5px!important;
}
.actions{
  padding: 0px!important;
  margin-top: -30px;
  text-align: center;
  z-index: 2;
}
@media(max-width: 768px){
  .actions{
    margin-top: -20px;
  }
}
</style>
