<template>

  <div class="container events">
    
    <div class="loadingText" v-if="isLoadingEvents">
      Updating list...
    </div>


    <div class="text-center" v-if="!isLoadingEvents && !allEvents.length">You have no events yet.</div>
    <div class="row event"  v-for="(event, key) in allEvents" :key="event.txid" :data-index="key">
      <EventItem :event="event" :keyIndex="key" @editEvent="$emit('editEvent', $event)"/>
    </div>
  </div>

</template>

<script>
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
import EventItem from '@/components/admin/elements/EventItem.vue' 
export default {
  name: 'Manage-page',
  mixins: [Global],
  props: ['showCreatePopin'],
  data: function(){
    return {
      allEvents: [],
      isLoadingEvents: false,
      imgix: undefined
    }
  },
  created(){
    this.getAllEvents(this.$store.state.user.userAddress)
  },
  components: {
    EventItem
  },
  methods:{
    getAllEvents: async function(address = ''){
      this.isLoadingEvents = true
      let regex = encodeUrl("^event_(.*)") 
      if(address!=''){
        regex = encodeUrl("^event_(.*)_"+address)
      }
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedEvents(getList)

      // get all display as imgx
      let imgixData =[]
      let _this = this
      orderedList.forEach(function(e){
        imgixData.push({id: e.txid, url: _this.getIpfsLink(e.event_displayCid)})
      })
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imgix", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(imgixData)
          });
      const imgix = await response.json();
      this.imgix = imgix.res

      this.allEvents = orderedList
      this.isLoadingEvents = false
    }
  },
  watch: {
    showCreatePopin: function(show){
      let _this = this
      if(!show){
        setTimeout(function(){
          _this.getAllEvents(_this.$store.state.user.userAddress)
        }, 2000)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
