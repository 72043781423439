<template>
  <div class="main">
    <header>
      <router-link to="/" class="logo_wrap"><img class="logo" alt="Vue logo" src="@/assets/images/logo-white.svg"  @click="showMenu=false"></router-link>
      <font-awesome-icon icon="fa-solid fa-bars" class="burger" @click="showMenu=!showMenu"/>
      <div class="wrap_menu" :class="{'show':showMenu}">
        <font-awesome-icon icon="fa-solid fa-xmark" @click="showMenu=!showMenu"/>
        <ul class="main_menu">
          <li><router-link to="/" @click="showMenu=false">Home</router-link></li>
          <li><router-link to="/events" @click="showMenu=false">All events</router-link></li>
          <li><router-link to="/marketplace" @click="showMenu=false">Marketplace</router-link></li>
          <li><router-link to="/about" @click="showMenu=false">About</router-link></li>
        <!--  <li v-if="$store.state.user.isLogged"><router-link :to="'/admin/profile/'+$store.state.user.userAddress">Account</router-link></li> -->
        </ul>
        <ul class="admin_menu" v-if="$store.state.user.isLogged">
          <li><router-link :to="'/admin/profile/'+$store.state.user.userAddress" @click="showMenu=false"><font-awesome-icon icon="fa-solid fa-address-card" /> Profile</router-link></li>
          <li><router-link :to="'/admin/profile/'+$store.state.user.userAddress+'/owned'" @click="showMenu=false"><font-awesome-icon icon="fa-solid fa-ticket" /> Your Tickets</router-link></li>
          <li><router-link to="/admin/manage" @click="showMenu=false"><font-awesome-icon icon="fa-solid fa-calendar" /> Your events</router-link></li>
        </ul>
      </div>
    </header>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Main-page',
  props: {
    msg: String
  },
  data: function(){
    return {
      showMenu: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fa-xmark{
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 15px;
  color: white;
  cursor: pointer;
  font-size: 22px;
  display: none;
}
.burger{
  position: absolute;
  z-index: 400;
  color: white;
  top: 30px;
  left: 30px;
  font-size: 26px;
  cursor: pointer;
  display: none;
}
.main{
  .logo_wrap, .wrap_menu{
    text-align: center;
    z-index: 2;
    position: relative;
  }
  .wrap_menu{
    width: 100%;
  }
  .admin_menu{
    display: inline-flex;
    margin: auto;
    justify-content: center;
    padding: 5px 20px!important;
    background-color: black;
    border-radius: 8px;
    margin-top: 20px;
    li{
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
      a{
        color: white;
        font-size: 16px;
        text-decoration: none;
        letter-spacing: 1px;
        transition: all .4s ease;
        .svg-inline--fa{
          font-size: 15px;
          color: rgb(255, 255, 255);
          margin-right: 5px;
          transition: all .4s ease;
        }
        &:hover{
          color: #ccc;
          .svg-inline--fa{
            color: #ccc;
          }
        }
      }
      
    }
  }
  header{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 50px;
    padding-right: 180px;
  }
}
h3 {
  margin: 40px 0 0;
}
ul.main_menu {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}
ul.main_menu li {
  display: inline-block;
  margin-right:20px;
  text-transform: uppercase;
  color: white;
  font-size: 22px;
  letter-spacing: 5px;
  font-weight: 300;
  position: relative;
  
  &:last-child{
    margin-right: 0;
  }
  a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 0 10px 0 25px;
    border-bottom:solid 2px transparent;
    transition: all .4s ease;
    &:before{
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        background-color: white;
        transform: rotate(45deg);
        position: absolute;
        top: 10px;
        left: 5px;
        opacity:0;
        transition: all .4s ease;
      }
    &:hover, &.router-link-active{
      border-bottom:solid 2px white;
      &:before{
        opacity: 1;
      }
    }
  }
}
a {
  color: #42b983;
}
@media(max-width: 1200px){
  header{
    padding-right: 50px!important;
  }
  ul.main_menu li{
    margin-right: 20px;
    font-size: 18px;
    letter-spacing: 3px;
    a{
      padding: 0 0px 5px 20px;
      border-bottom: solid 1px white!important;
      &:before{
        left: 0px;
      }
    }
  }
}
  @media(max-width: 992px){
    header{
      flex-direction: column;
        .logo_wrap{
          margin-bottom: 20px;
        }
      }
  }
  @media(max-width: 768px){
    
    .burger, .fa-xmark{
      display: block;
    }
    header{
      padding-bottom: 0!important;
      .wrap_menu{
        position: fixed!important;
        z-index: 500!important;
        width: 70%!important;
        top: 0;
        left: -100vw;
        min-height: 100vh;
        background-color: rgba(0,0,0,0.9);
        padding: 30px 15px;
        transition: all .4s ease;
        overflow-y: auto;
        height: 100%;
        &.show{
          left: 0;
        }
        ul{
          display: flex;
          flex-direction: column;
          li{
            margin-right: 0;
            a{
              text-align: left;
              padding-left: 25px;
              padding-top: 5px;
              &:before{
                left: 3px;
                top: 13px;
              }
            }
          }
        }
        .admin_menu{
          width: 100%!important;
          text-align: left!important;
          padding: 15px 0!important;
          border-top: 1px dotted white;
          border-radius: 0;
          margin-top: 40px;
          &:before{
            
            display: block;
            content: "ACCOUNT";
            color: white;
            margin-top: -50px;
            left: 0;
            margin-bottom: 15px;
            margin-left: 25px;
          }
          li{
            margin-bottom: 10px;
          }
        }
      }
  }
  }

</style>
<style lang="scss">
.bg{
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  
  &:before{
    display: block;
    content: "";
    width: 100%;
    height: 40%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 49%, rgba(0,0,0,0) 100%);
    bottom: 0;
    position: absolute;

  }
}
.content_wrapper{
  position: relative;
  z-index: 2;
}
</style>
