<template>
  
  <div id="admin-owned">
    <div class="container">
      <div class="row">
        <div class="bg"></div>
        <div class="content_wrapper">
          <h3 class="mt-4 mb-4">YOUR TICKETS</h3>
          <div class="col-12">
              <div class="switchForSale" @click="toggleForSale">
                <span 
                  class="sales me-2" 
                  :class="{bold: toggleSwitch=='FORSALE'}"
                  >For sale</span> 

                <font-awesome-icon icon="fa-solid fa-toggle-on" title="Switch" 
                  class="fas fa-toggle-on" 
                  :class="{on: toggleSwitch=='FORSALE'}" 
                   ref="switcher"></font-awesome-icon>

                <span class="bids ms-2" 
                  :class="{bold: toggleSwitch=='NOTFORSALE'}">Not for sale</span>
              </div>
            </div>

          <div class="loadingText" v-if="loading">
            Loading your tickets...
          </div>
          <div class="events " v-else>
            
              <div class="event noborder" v-for="(nft, index) in nftList" :key="nft.assetId">
                <app-owned 
                  :nftInfo="nft" 
                  :index="index"
                  :forsale="toggleSwitch"
                  @removeNFT="removeNFTfromChild"
                ></app-owned>
              </div>
              <button class="btn btn-primary mb-2 bigger loadNFT" v-if="loadMore && !loading" @click="getNextNFTs()">Load more</button>
              <div  v-if="!loadMore && !loading" class="text-center nft-result">{{ nftList.length ? 'All tickets loaded' : 'No tickets found' }}</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nft from '@/components/admin/elements/ownedNFTdetail.vue'
import encodeUrl from 'encodeurl';
export default {
  name: 'profile-admin-owned-page',
  data: function(){
    return {
      loading: false,
      loadMore: false,
      loadAfter: undefined,
      allNFT: [],
      nftList: [],
      toggleSwitch: "FORSALE",
      nftIndex: 0,
      elementsInit: 1000,
      elementsInitMobile: 1000,
      elementsPerScroll: 10,
      elementsPerScrollMobile: 6
    }
  },
  components: {
    'app-owned': Nft
  },
  created: async function(){
    await this.getAllNFT2ndMarket()
    if(!this.allNFT.length){
      this.toggleSwitch = "NOTFORSALE"
    }
  },
  watch: {
    toggleSwitch: function(){
      this.resetNFT()
      if(this.toggleSwitch == "FORSALE"){
        this.getAllNFT2ndMarket()
      }else{
        this.getAllNFT()
      }
    }
  },
  methods: {
    toggleForSale: function(){
      this.toggleSwitch = this.toggleSwitch == "FORSALE" ? "NOTFORSALE" : "FORSALE"
    },
    removeNFTfromChild: function(issueId){
      console.log("removeNFTfromChild called for "+issueId)
      var removeIndex = this.nftList.map(function(item) { return item.assetId; }).indexOf(issueId);
      this.nftList.splice(removeIndex, 1);
      this.allNFT.splice(removeIndex, 1);
    },
    getNextNFTs: function(){
      let _this = this;
      if( _this.nftIndex == 0){
        _this.nftList = this.allNFT.slice(0, this.elementsPerScroll);
      }else{
        _this.nftList = this.allNFT.slice(0, _this.nftIndex + _this.elementsPerScroll);
      }
      _this.nftIndex = _this.nftList.length
      if(_this.nftList.length < this.allNFT.length){
        _this.loadMore = true
      }else{
        _this.loadMore = false
      }
    },
    filterNFT: function(data){
      const _this = this
      let newDataDappFiltered  = Object.values(data).filter(({issuer}) => issuer == _this.$store.state.config.dapp_addr_events)
      return newDataDappFiltered
    },
    orderNFT: function(data){
      let orderedNFTList = Object.values(data).sort(function(a,b){
        if(b.issueTimestamp){
          return +b.issueTimestamp - +a.issueTimestamp;
        }
      });
      return orderedNFTList;
    },
    resetNFT: function(){
      this.allNFT = []
      this.nftList = []
      this.nftIndex = 0
      this.loading = false
      this.loadMore = false
      this.loadAfter = undefined
    },
    getAllNFT: async function(filter = ""){
      this.loading = true
      const _this = this
      let url = filter != "" ? this.$store.state.config.nodeURL+"/assets/nft/"+_this.$route.params.addr+"/limit/"+this.elementsInit+"?after="+filter : this.$store.state.config.nodeURL+"/assets/nft/"+_this.$route.params.addr+"/limit/"+this.elementsInit
      console.log("URL")
      console.log(url)
      await fetch(url).then(res => res.json()).then(data => {
        let filteredData = _this.filterNFT(data) // filter on dapp addr
        if(filteredData){
          let tempNFTList = [..._this.allNFT, ...Object.values(filteredData)] // append to list on every call with after id
          let orderedList = _this.orderNFT(tempNFTList) // reorder by DESC date 
          _this.allNFT = orderedList
        }
        if(data.length == this.elementsInit){
          _this.getAllNFT(data[data.length-1].assetId)
        }else{
          this.getNextNFTs()
          _this.loading = false
        }
        console.log("_this.allNFT _this.allNFT _this.allNFT")
        console.log(_this.allNFT)
        
      }).catch(err => console.error(err))
      
    },
    getAllNFT2ndMarket: async function(){
      this.loading = true
      const _this = this
      let regex = encodeUrl(`^sale2nd_info_${_this.$route.params.addr}_(.*)_OPEN`)

      await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex).then(res => res.json()).then(data => { 
        let filteredData = []
        data.forEach((elm) => {
          
          let splitVal = elm.value.split("_")
          let splitKey = elm.key.split("_")
          console.log(splitVal)
          console.log(splitKey)
          filteredData.push({forsale: true, assetId: splitVal[3], issueTimestamp: splitVal[0], ticketid: splitVal[4], eventId: splitVal[5], price: splitVal[1], asset: splitVal[2], organizerAddr: splitKey[5]}) // issueTimestamp == added date on secondary
        })
        
        if(filteredData.length){
          let tempNFTList = [..._this.allNFT, ...Object.values(filteredData)]
          let orderedList = _this.orderNFT(tempNFTList) // reorder by DESC date 
          console.log("hihihih")
          console.log(orderedList)
          _this.allNFT = orderedList
          this.nftforsale=true
        }else{
          this.nftforsale=false
        }
        this.getNextNFTs()
          _this.loading = false
      }).catch(err => console.error(err))

      return
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .bg{
    background: url("@/assets/images/bg-blue.jpg");
  }
  @media(max-width: 768px){
    h3{
      text-align: center;
    }
  }
  
</style>
