<template>
  <div class="page" id="event-detail">
    <div class="loadingText" v-if="isLoadingEvents">
      Loading event detail...
    </div>
    <div class="container mt-3 detail" v-if="eventData">
      <div class="bg" :style="`background: url(${getIpfsLink(eventData.event_displayCid)});`"></div>
      <div class="content_wrapper">
        <div class="row">
          <div class="col-lg-5"><img :src="getIpfsLink(eventData.event_displayCid)" class="d-block img-fluid mx-auto"></div>
          <div class="col-lg-7 ps-lg-5">

            <h2>{{eventData.event_name}}</h2>
            <div class="date">{{ eventData.event_date }}</div>
            <div class="desc">{{eventData.event_desc}}</div>
            <div class="tickets_sold">Total tickets sold: <span>{{eventData.event_totalIssued}}</span></div>
            <Creator :user_addr="eventData.user_addr" class="mb-2" />
            
            <div class="loadingTickets" v-if="isLoadingTickets">
              Updating list...
            </div>

            <div class="container tickets" v-if="ticketsData">
              <div class="row event"  v-for="(ticket, key) in ticketsData" :key="ticket.txid" :data-index="key">
                <TicketItem :ticket="ticket" @getTicketsByEventId="getTicketsByEventId" @updateEventTotalSold="updateEventTotalSold"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import Creator from '@/components/admin/elements/EventItemCreator.vue' 
import TicketItem from '@/components/elements/TicketItemFront.vue' 
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
export default {
  name: 'Event-page-details',
  mixins: [Global],
  data: function(){
    return {
      showNewTicketPopin: false,
      eventData: null,
      ticketsData: null,
      ticketToEdit: null,
      isLoadingTickets: false,
      isLoadingEvents: false
    }
  },
  components: {
    'Creator': Creator,
    'TicketItem': TicketItem
  },
  async created(){
    await this.getEventById(this.$route.params.eventId)
    this.getTicketsByEventId(this.$route.params.eventId)
  },
  methods:{
    getEventById: async function(eventId){
      this.isLoadingEvents = true
      let regex = encodeUrl("^event_(.*)") 
      regex = encodeUrl("^event_(.*)_"+eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedEvents(getList)
      this.eventData = {...orderedList[0]}
      this.isLoadingEvents = false
    },
    getTicketsByEventId: async function(eventId){
      this.isLoadingTickets = true
      let regex = encodeUrl("^ticket_(.*)") 
      if(eventId!=''){
        regex = encodeUrl("^ticket_(.*)_"+eventId+"_(.*)")
      }
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedTickets(getList)
      this.ticketsData = {...orderedList}
      this.isLoadingTickets = false
    },
    updateEventTotalSold: async function(){
      let regex = encodeUrl("^event_totalIssued_"+this.$route.params.eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      this.eventData.event_totalIssued = getList[0].value
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .addTicket{
    cursor: pointer;
  }

  .tickets_sold{
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    span{
      font-weight: 500;
    }
  }
  
  .bg{
    filter: brightness(15%);
  }
  .content{
    

  }

</style>
