<template>
  <div class="position-relative">
    <ul class="d-flex justify-content-center">
      <li class="me-3" :class="{'active': showOfferList}" @click="showOfferList=true; showHistoryList=false;">OFFERS LIST</li>
      <li :class="{'active': showHistoryList}" @click="showOfferList=false; showHistoryList=true;">TX HISTORY</li>
    </ul>
    <div class="wrap_tabs">
      <transition name="fade" >
        <div id="offerList" v-if="showOfferList">
          <table class="table table-striped" v-if="offersList!=undefined">
            <thead>
              <tr>
                <td>DATE</td>
                <td>OFFER FROM</td>
                <td>OFFERED PRICE</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody>
              <tr v-if="offersList.length == 0 ">
                <td colspan="4" v-if="bidsLoading">Checking offers...</td>
                <td colspan="4" v-else>No offer(s) found.</td>
              </tr>
              <tr v-for="(offer, index) in offersList" :key="index" v-else>
                <td>{{ setDate(offer.date) }} <span class="offerTime">at {{ setTime(offer.date) }}</span></td>
                <td>
                  <router-link :to="'/user/'+offer.bidOwner" exact v-if="offer.bidOwner != undefined">{{ shortTxAddr(offer.bidOwner,5) }}</router-link>
                  <span v-else>Checking address...</span>
                </td>
                <td><strong>{{ numberWithSpaces(offer.price) }} {{ offer.assetId }}</strong> <!-- <span class="usdValue">({{numberWithSpaces(offer.priceInUsd.toFixed(2))+"$"}})</span> --><app-UsdPrice :offer="offer" :color="'grey'"/></td>
                <td>
                  <div v-if="offer.status == 'OPEN' && offer.saleOwner == $store.state.user.userAddress && ticket.ticket_maxUse != 0">
                    <button class="acceptOffer mr-2" :data-eventId="offer.eventId" :data-saleOwner="offer.saleOwner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" :data-ticketId="offer.ticketId" @click.prevent="emitBidAction($event, 'accept', $route.params.nftId)">ACCEPT</button>
                    <button class="rejectOffer mr-2" :data-eventId="offer.eventId" :data-saleOwner="offer.saleOwner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" :data-ticketId="offer.ticketId" @click.prevent="emitBidAction($event, 'cancel', $route.params.nftId)">REJECT</button>
                  </div>
                  <div v-else-if="offer.status == 'OPEN' && offer.bidOwner == $store.state.user.userAddress">
                    <button class="cancelOffer" :data-eventId="offer.eventId" :data-saleOwner="offer.saleOwner" :data-assetId="offer.assetId" :data-basePrice="offer.basePrice" :data-bidid="offer.bidid" :data-bidOwner="offer.bidOwner" :data-ticketId="offer.ticketId" @click.prevent="emitBidAction($event, 'cancel', $route.params.nftId)">CANCEL</button>
                  </div>
                    <div v-else v-html="setStatus(offer.status)" class="offerStatus"></div>
                </td>
              </tr> 
            </tbody>
          </table>
        </div>
      </transition>

      <transition name="fade">
        <div id="txhistory" v-if="showHistoryList">
          <table class="table table-striped" >
            <thead>
              <tr>
                <td>DATE</td>
                <td>ACTION</td>
                <td>FROM</td>
                <td>TO</td>
                <td>PRICE</td>
              </tr>
            </thead>
            <tbody v-if="tokenHistory && tokenHistory.length">
              <tr v-for="(tx, index) in tokenHistory" :key="index">
                <td>{{setDate(tx.timestamp)}} <span class="offerTime">at {{ setTime(tx.timestamp) }}</span></td>
                <td><a :href="getExplorerUrl()+'/tx/'+tx.id" target="_blank">{{ setAction(tx.status) }}</a></td>
                <td>
                  <router-link :to="'/user/'+tx.seller" exact v-if="tx.status == 'OPEN' || tx.status == 'CLOSED'">{{shortTxAddr(tx.seller,5)}}</router-link>
                  <div v-if="tx.status == 'CANCELLED'">SIGN Art dApp</div>
                </td>
                <td>
                  <router-link :to="'/user/'+tx.buyer" exact v-if="tx.status == 'CLOSED'">{{shortTxAddr(tx.buyer,5)}}</router-link>
                  <div v-if="tx.status == 'OPEN'">SIGN Art dApp</div>
                  <router-link :to="'/user/'+tx.seller" exact v-if="tx.status == 'CANCELLED'">{{shortTxAddr(tx.seller,5)}}</router-link>
                </td>
                <td>{{ tx.price }} {{ tx.ticker }}</td>
              </tr> 
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">No transaction history.</td>
              </tr> 
            </tbody>
          </table>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import encodeUrl from 'encodeurl';
import UsdPrice from '@/components/elements/UsdPrice.vue' 
import global from '@/mixins/global.js';
export default {
  mixins: [global],
  props: ["ticket", "event", "bidAction", "refreshOfferList"],
  data: function(){
    return {
      signToUsd: null,
      bidsLoading: false,
      tokenHistory: [],
      offersList: [],
      showOfferList: true,
      showHistoryList: false
    }
  },
  components: {
    'app-UsdPrice': UsdPrice
  },
  watch: {
    refreshOfferList: async function(){
      console.log("watch refreshOfferList called")
      let _this = this
      setTimeout(async function(){
        await _this.getOffersListSecondary({NFTid:_this.$route.params.nftId, addr:""})
        await _this.getHistoryOn2nd()
      }, 2000)
      
    }
  },
  async created(){
    console.log("this.event")
    console.log(this.event)
    await this.getOffersListSecondary({NFTid:this.$route.params.nftId, addr:""})
    await this.getHistoryOn2nd()

    console.log("offersList")
    console.log(this.offersList)

    console.log("tokenHistory")
    console.log(this.tokenHistory)
  },  
  methods: {
    setAction: function(status){
      let res
      switch(status){
        case "OPEN":
          res = "FOR SALE"
        break;

        case "CANCELLED":
          res = "CANCELLED"
        break;

        case "CLOSED":
          res = "SOLD"
        break;

        default:
      }
      return res
    },
    emitBidAction: function(event, type, nftid){
      this.$emit("bidAction", {e: event, type: type, nftid: nftid})
    },
    setStatus: function(e){
      if(e == "CANCELED"){e = "CANCELLED"}
      return e == "CLOSED" ? '<i class="fas fa-check mr-2"></i> ' + e : e == "OPEN" ? '<i class="far fa-clock mr-2"></i> ' + e : '<i class="fas fa-times mr-2"></i> ' + e
    },
    getOffersListSecondary: async function(params){ // eventId and art issuer addr
      this.bidsLoading = true
      this.signToUsd = this.$store.state.priceInUSDN.signPriceInUsd
      let _this = this
      let tempOffersList = []
      let regex

      if(params.NFTid == ""){
        regex = encodeUrl("^offer2nd(.*)" + params.addr +"(.*)")
      }else{
        regex = encodeUrl("^offer2nd(.*)"+ params.NFTid +"(.*)")
      }
      
      await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex).then(res => res.json()).then(async data => {
        data.map(function(e){
          console.log("e")
          console.log(e)
          let offersKeyValues = e.key.split("_")
          let offersValues = e.value.split("_")
          let [ticker, issuePrice] = _this.getPriceWithTicker(offersValues[1])
          let offersValuesObj = {
            organizerAddr: offersValues[5],
            eventId: offersValues[4],
            ticketId: offersKeyValues[2],
            nftId: offersKeyValues[5],
            saleOwner: offersKeyValues[3],
            bidOwner: offersKeyValues[1],
            bidid: offersKeyValues[4],
            status: offersKeyValues[6],
            assetId: ticker,
            price: issuePrice,
            basePrice:offersValues[1],
            date: offersValues[0]
          }
        
          offersValuesObj.priceInUsd = offersValuesObj.basePrice / Math.pow(10, 8) * _this.signToUsd
          tempOffersList.push(offersValuesObj)
        })
        let sortedList = tempOffersList.sort((a,b) => new Date(Number(a.date)) - new Date(Number(b.date)));
        let sortedByPriceList = sortedList.sort((a,b) => b.priceInUsd - a.priceInUsd);
        // _this.offersList = sortedByPriceList // old version

        let openOnly = sortedByPriceList.filter(offer => {
          return offer.status == "OPEN"
        })
        openOnly = openOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);
        _this.currentHighestBid = openOnly[0]

        let closedOnly = sortedByPriceList.filter(offer => {
          return offer.status == "CLOSED"
        })
        closedOnly = closedOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);

        let canceledOnly = sortedByPriceList.filter(offer => {
          return offer.status == "CANCELED" || offer.status == "CANCELLED"
        })
        canceledOnly = canceledOnly.sort((a,b) => b.priceInUsd - a.priceInUsd);

        _this.offersList = [...openOnly, ...closedOnly, ...canceledOnly];
        console.log("offersList")
        console.log(_this.offersList)
        this.bidsLoading = false
      })
    },
    getHistoryOn2nd: async function(){
      let _this = this
      let txinfos = []
      let regex = encodeUrl("^sale2nd_info_(.*)_"+ _this.$route.params.nftId+"_(.*)_CLOSED")
      let data = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
      data = await data.json()


      Object.values(data).forEach((curr) => {
          const _key = curr.key.split("_");
          const ownerAddr = _key[2];
          const saleStatus = _key[_key.length-1];
          let val = curr.value.split("_")
            
            if(saleStatus == "CLOSED"){
              // uncomment if have precise timestamp in db later to list other actions.
              /* let res_previous = {
                id: val[6],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: "OPEN"
              } */
              let [ticker, price] = val.includes("CLOSED-OFFER") ? this.getPriceWithTicker(val[12]) : this.getPriceWithTicker(val[1]) 
              console.log("val")
              console.log(val)
              console.log("_key")
              console.log(_key)
              let res = {
                id: val[10],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: saleStatus,
                buyer: val[11]
              } 
              //txinfos.push(res_previous);
              txinfos.push(res);
            }
          /* 
             if(saleStatus == "OPEN"){
               let res = {
                id: val[6],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: saleStatus
              } 
              txinfos.push(res);
            }


            if(saleStatus == "CANCELLED"){
              let res_previous = {
                id: val[6],
                seller: ownerAddr,
                timestamp: val[0],
                price: price,
                ticker: ticker,
                status: "OPEN"
              }

              let res = {
                id: val[9],
                seller: ownerAddr,
                timestamp: val[8],
                price: price,
                ticker: ticker,
                status: saleStatus
              } 
              

              txinfos.push(res_previous);
              txinfos.push(res);
            } */
          
          
        }, {});

        // Since we can only use lastblock.timestamp and 2 entries could have same timestap, we add 1 millisecond to each timestamp before ordering by timestamp
        // because entries are returned in correct order for reason i don't get at all :)
        /* let increment = 0
        txinfos.map(function(a){
          increment += 100
          return a.timestamp = +a.timestamp + increment
        }); */
        
        // now can order by timestamp...
        let orderedHistory = Object.values(txinfos).sort(function(a,b){
          return b.timestamp - a.timestamp;
        });
        console.log("orderedHistory")
        console.log(orderedHistory)
        _this.tokenHistory = orderedHistory
    },
  }
}
</script>
<style lang="scss" scoped>
  ul{
    margin-bottom: 0;
    position: absolute;
    z-index: 2;
    top: -49px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
      li{
        padding: 10px 20px;
        border: dotted 2px transparent;
        border-radius: 13px 13px 0 0;
        cursor: pointer;
        &.active{
          border: dotted 2px white;
          border-bottom: 2px solid black;
        }
      }
    }
.wrap_tabs{
  margin-top: 49px;
  position: relative;
  border-top: 2px dotted white;
  #offerList, #txhistory{
    color: white;
    width: 100%;
    &.fade-leave-active, &.fade-enter-active{
      position: absolute;
    }
  }
  #offerList, #txhistory{
    overflow-x: auto;
    .table-striped{
      min-width: 600px;
    }
  }
  .table-striped{
    color: white!important;
    thead{
      td{
        font-weight: 600;
        border-bottom: 1px solid rgb(130, 130, 130);
      }
    }
    td {
      color: white!important;
      font-size: 14px;
      letter-spacing: 0.5px;
      text-align: center;
      border-bottom: 1px solid #333;
      a{
        color: white;
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1px solid white;
      }
    }
  }

}

@media(max-width: 560px){
ul{
      li{
        padding: 10px 10px;
      }
    }
  }
</style>