import { createApp } from 'vue'
import App from './App.vue'
import router from './routes.js'
import { store } from './stores/store'
import VueMobileDetection from "vue-mobile-detection";
import VueLazyload from 'vue-lazyload'
import LazyLoadDirective from "./directives/LazyLoad";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { Tooltip } from "bootstrap";

import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"

import "@fontsource/open-sans/500-italic.css"
import "@fontsource/open-sans/600-italic.css"
import "@fontsource/open-sans/700-italic.css"
import "@fontsource/open-sans/800-italic.css"

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
import { faPlus, faCirclePlus, faXmark, faArrowRightToBracket, faCheckCircle, faQrcode, faChevronRight, faLink, faToggleOn, faTriangleExclamation, faChevronUp, faBars, faAddressCard, faTicket, faCalendar } from '@fortawesome/free-solid-svg-icons'

/* add each imported icon to the library */
library.add(faPlus, faCirclePlus, faXmark, faArrowRightToBracket, faCheckCircle, faQrcode, faChevronRight, faLink, faToggleOn, faTriangleExclamation, faChevronUp, faBars, faAddressCard, faTicket, faCalendar)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //let parentRoute = to.matched[0].name
    if (to.matched[0].name == "admin" && !store.state.user.isLogged) {
      let newPath = to.path.substring(0, to.path.indexOf("/"))
      next({ path: newPath })
    } else {
      next() 
    }
  } else {
    next() 
  }
})

createApp(App)
.use(router)
.use(store)
.use(VueLazyload)
.use(VueMobileDetection)
.use(new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
}))
.directive("lazyload", LazyLoadDirective)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')


