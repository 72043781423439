import { createWebHistory, createRouter } from "vue-router";
import Home from './components/pages/Home.vue'
import About from './components/pages/About.vue'
import Events from './components/pages/Events.vue'
import Event from './components/pages/Event.vue'
import EventCheckIn from './components/pages/EventCheckin.vue'
import Confirmation from './components/pages/Confirmation.vue'
import Profile from './components/pages/Profile.vue'
import Marketplace from './components/marketplace/Home.vue'
import MarketplaceTicketDetail from './components/marketplace/TicketDetail.vue'

import AdminManage from './components/admin/Manage.vue'
import AdminEvent from './components/admin/Event.vue'
import AdminProfile from './components/admin/Profile.vue'
import AdminOwned from './components/admin/OwnedNFTs.vue'

const routes = [
  { path: "", component: Home, 
    name: "home",
    meta: {
      title: 'Welcome to SIGN Tickets'
    },
  },
  { path: "/about", component: About, 
    name: "about",
    meta: {
      title: 'Welcome to SIGN Tickets - About'
    }
  },
  { path: "/profile", component: Profile, 
    name: "profile",
    meta: {
      title: 'Welcome to SIGN Tickets - Profile'
    }
  },
  { path: "/events", component: Events, 
    name: "events",
    meta: {
      title: 'List all existing events'
    }
  },
  { path: "/event/:eventId", component: Event, 
    name: "event",
    meta: {
      title: 'Event detail'
    }
  },
  { 
    path: "/checkin/:eventId/", component: EventCheckIn, 
    name: "admin-event-checkin",
    meta: {
      title: 'Event check in'
    }
  },
  { path: "/confirmation/:nftId/:nftName", component: Confirmation, 
    name: "confirmation",
    meta: {
      title: 'Welcome to SIGN Tickets confirmation page'
    },
  },
  { path: "/marketplace", component: Marketplace, 
    name: "marketplace",
    meta: {
      title: 'Welcome to SIGN Tickets - Marketplace'
    }
  },
  {
    name: 'marketplace-ticket-detail',
    path: '/marketplace/ticket/:eventId/:ticketId/:nftId/:issueNum/:maxIssuable',
    component: MarketplaceTicketDetail,
    meta: {
      title: 'Ticket Detail'
    }
  },
  {
    name: 'admin-profile',
    path: '/admin/profile/:addr',
    component: AdminProfile,
    meta: {
      title: 'Manage your profile'
    }
  },
  { 
    path: "/admin/profile/:addr/owned", component: AdminOwned, 
    name: "admin-owned",
    meta: {
      title: 'Your owned NFTs'
    }
  },
  { 
    path: "/admin/manage", component: AdminManage, 
    name: "admin-manage",
    meta: {
      title: 'Manage your events'
    }
  },
  { 
    path: "/admin/event/:eventId", component: AdminEvent, 
    name: "admin-event",
    meta: {
      title: 'Manage your event'
    }
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;