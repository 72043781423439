<template>
  <div id="admin-event-detail">
    
    <Transition name="fade">
      <app-new-ticket :show="showNewTicketPopin" :closeNewTicketPopin="closeNewTicketPopin" :ticketToEdit="ticketToEdit"/>
    </Transition>

    <div class="container" v-if="eventData">
      <div class="bg" :style="`background: url(${getIpfsLink(eventData.event_displayCid)});`"></div>
      <div class="content_wrapper">
        <div class="row">
          <div class="col-lg-5 mb-lg-0 mb-3"><img :src="getIpfsLink(eventData.event_displayCid)" class="d-block img-fluid mx-auto"></div>
          <div class="col-lg-7">

            <h2>{{eventData.event_name}}</h2>
            <div class="date">{{ eventData.event_date }}</div>
            <div class="desc">{{eventData.event_desc}}</div>
            <div class="tickets_sold">Total tickets sold: <span>{{eventData.event_totalIssued}}</span></div>
            <div class="d-flex justify-content-between align-items-center">
              <Creator :user_addr="eventData.user_addr" />
              <ul class="text-center">
                <li @click.prevent="showNewTicketPopin = true" class="addTicket d-inline-block btn btn-primary">
                  <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon> Add tickets
                </li>
              </ul>
            </div>
            
            <div class="loadingTickets" v-if="isLoadingTickets">
              Updating list...
            </div>

            <div class="container tickets" v-if="ticketsData">
              <div class="row event"  v-for="(ticket, key) in ticketsData" :key="ticket.txid" :data-index="key">
                <TicketItem :ticket="ticket" @editTicket="editTicket" @getTicketsByEventId="getTicketsByEventId"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTicket from '@/components/admin/elements/NewTicket.vue' 
import Creator from '@/components/admin/elements/EventItemCreator.vue' 
import TicketItem from '@/components/admin/elements/TicketItem.vue' 
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
export default {
  name: 'Event-page',
  mixins: [Global],
  data: function(){
    return {
      showNewTicketPopin: false,
      eventData: null,
      ticketsData: null,
      ticketToEdit: null,
      isLoadingTickets: false
    }
  },
  components: {
    'app-new-ticket': NewTicket,
    'Creator': Creator,
    'TicketItem': TicketItem
  },
  async created(){
    await this.getEventById(this.$route.params.eventId)
    this.getTicketsByEventId(this.$route.params.eventId)
  },
  methods:{
    closeNewTicketPopin: function(){
      this.showNewTicketPopin = false
    },
    getEventById: async function(eventId){
      let regex = encodeUrl("^event_(.*)") 
      regex = encodeUrl("^event_(.*)_"+eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedEvents(getList)
      this.eventData = {...orderedList[0]}
    },
    getTicketsByEventId: async function(eventId){
      
      this.isLoadingTickets = true
      let regex = encodeUrl("^ticket_(.*)") 
      if(eventId!=''){
        regex = encodeUrl("^ticket_(.*)_"+eventId+"_(.*)")
      }
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedTickets(getList)
      this.ticketsData = {...orderedList}
      console.log("niquemouk! this.ticketsData")
      console.log(this.ticketsData)
      this.isLoadingTickets = false
    },
    editTicket: function(ticketData){
      this.ticketToEdit = ticketData
      this.showNewTicketPopin = true
    }
  },
  watch: {
    showNewTicketPopin: function(show){
      console.log(show)
      let _this = this
      if(!show){
        console.log("pas chaud!!")
        console.log(show)
        this.ticketToEdit = null
        setTimeout(function(){
          _this.getTicketsByEventId(_this.$route.params.eventId)
        }, 3000)
        
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .addTicket{
    cursor: pointer;
  }
  .date{
    font-weight: bold;
    margin-bottom: 20px;
  }
  .desc{
    margin-bottom: 20px;
  }
  .tickets_sold{
    margin-bottom: 20px;
    font-style: italic;
  }
  .bg{
    filter: brightness(15%);
  }
</style>
