<template>
  <div id="widgetUser">   
      <button id="login" @click="chooseSigningMethod()" v-show="!$store.state.user.isLogged">
        <font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket" /> <span> Log in</span>
      </button>
      <div :class="$store.state.user.isLogged ? 'connected' : ''" @mouseover="isHovering=true" @mouseleave="isHovering=false" class="position-relative">
        <a href="#" class="goAccount" v-show="$store.state.user.isLogged">
          <img :src="$store.state.user.thumbnail != '' ? getIpfsLink($store.state.user.thumbnail) : emptyThumb" class="thumb account"/> <span>{{ shortTxAddr($store.state.user.userAddress, 5) }}</span>
        </a>
          <div class="actions" v-if="isHovering && $store.state.user.isLogged">
            <button @click="goToAccount()">Profile</button>
            <button @click="switchAccount()">Switch account</button>
            <button @click="logout()">Logout</button>
          </div>
      </div>

  </div>
</template>

<script>
import Global from '@/mixins/global.js'
export default {
  mixins: [Global],
  data: function(){
    return {
      isHovering: false,
      thumbURL: this.$store.state.user.thumbnail,
    }
  },
  watch:{
    '$store.state.user.userReady': function(){
      if(this.$store.state.user.isLogged && this.$router.currentRoute._value.name!="confirmation"){
          this.goToAccount()
      }
    }
  },
  methods: {
    logout: function(){
      this.isHovering=false
      this.$store.dispatch("logoutUser");
      this.goToHome()
    },
     switchAccount: function(){
      this.isHovering=false
      this.$store.dispatch("logoutUser")
      this.chooseSigningMethod()
     },
     chooseSigningMethod(){
      if(this.$router.currentRoute._value.name!="confirmation"){
       this.$store.state.loginRedirect=true
      }else{
        this.$store.state.loginRedirect=false
      }
       if(this.$store.state.user.isLogged){
         console.log("User already logged in")

       }else{
         this.$store.state.signingChoice.show = true
       }
    }
  }
}
</script>

<style lang="scss" scoped>
.actions{
  display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 500;
    background-color: transparent;
    padding: 10px;
    width: 80%;
    margin-top: 0px;
    border-radius: 0 0 20px 20px;
    border: solid 1px white;
    border-top: 0;
    margin-left: 20px;
    border-top: 2px solid black;
    @media(max-width: 1200px){
      position: fixed;
      right: 0;
      top: 0;
      border: 0;
      justify-content: flex-end;
      flex-direction: row;
      padding: 15px 75px;
      width: 100%;
      padding-left: 0;
    }
  button{
    font-size: 14px;
    background-color: transparent;
    border: 0;
    color: white;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
#widgetUser {
  color: white;
  border-radius: 25px;
  transition: all .4s ease;
  position: absolute;
  top: 70px;
  right: 50px;
  border: solid 1px white;
  font-weight: 300;
  z-index: 2;

  #login {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: all .4s ease;
    outline: none;
    padding: 6px 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    span {
      font-weight: 300;
      display: inline-block;
      padding-left: 10px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 147%;
      text-align: center;
      letter-spacing: -0.015em;
      color: #FFFFFF;
    }

      &:hover {
        background-color: #1E1A1A;
      }
  }

  &.open {
    margin-right: 0;
  }

  .connected {
    img {
      border-radius: 50%;
      width: 22px;
      margin-right: 10px;
    }

    a {
      padding: 5px 20px 5px 7px;
      color: white;
      display: flex;
      align-items: center;
      transition: all .4s ease;
      justify-content: flex-start;
      font-size: 14px;
      border-radius: 50px;
      text-decoration: none;
      &:hover {
        background-color: #1E1A1A;
      }
    }
  }
  @media(max-width:1200px) {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 25222;
    #login{
      padding: 12px;
      span{
        display: none;
      }
    }
    .goAccount{
      padding: 5px!important;
      span{
        display: none;
      }
      img{
        margin-right: 0;
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }
  }

}

</style>