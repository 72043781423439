<template>
  <div class="page">
    <div class="bg" :style="`background: url(${getIpfsLink(eventData.event_displayCid)});`"></div>
    <div class="content_wrapper">
      <transition name="fade">
        <app-bid 
        :event="eventData" 
        :ticket="ticketsData" 
        :secondary="secondaryData" 
        :showBid="showBid" 
        @hideBid="hideBid"
        @updateOfferList = "updateOfferList" 
        :currentHighestBid="currentHighestBid" 
        v-if="showBid"></app-bid>
      </transition>
      <transition name="fade">
        <app-bid-actions 
        :bidData="bidData" 
        :showbidData="showbidData" 
        @hideBidData="hideBidData" 
        @updateOfferList = "updateOfferList"
        v-if="showbidData"></app-bid-actions>
      </transition>

      <div class="loadingText" v-if="isLoadingEvents">
        Loading event and ticket detail...
      </div>
      <div class="container mt-3 detail" v-if="eventData">
        
        <div class="row">
          <div class="col-lg-5"><img :src="getIpfsLink(eventData.event_displayCid)" class="d-block img-fluid mx-auto"></div>
          <div class="col-lg-7 ps-lg-5">
            <h2>{{eventData.event_name}}</h2>
            <div class="date">{{ eventData.event_date }}</div>
            <div class="desc">{{eventData.event_desc}}</div>   
            <Creator :user_addr="eventData.user_addr" />
            
            <div class="loadingTickets" v-if="isLoadingTickets">
              Updating list...
            </div>

            <div class="ticket_detail mt-4" v-if="ticketsData">
              <div class="row event">
                <div class="col-12 mb-3">
                  <div class="name d-flex align-items-center">
                    {{ticketsData.ticket_name}}  
                    <span 
                      class="ticket_used ms-3" 
                      :class="{'used': ticketsData.ticket_amountUsed!=0}" 
                      data-bs-toggle="tooltip" data-bs-placement="bottom" :title="ticketsData.ticket_amountUsed!=0?'Collectible only, cannot give access to the event':'Buyer of this ticket can use it to access the event'"
                    >{{ticketsData.ticket_amountUsed!=0 ? 'COLLECTIBLE':'OPEN TICKET'}}</span>
                  </div>
                  <div class="desc">{{ticketsData.ticket_desc}}</div>
                </div>
                <div class="col-lg-6 smaller">
                  <div class="price">Initially sold at: {{numberWithSpaces(ticketsData.ticket_price / Math.pow(10,8))}} SIGN</div>
                  <div class="used">Used: {{ticketsData.ticket_amountUsed}} of {{ticketsData.ticket_maxUse}}</div>
                  <div class="edition" v-if="issueNum">Ticket edition: <span>{{issueNum}} of {{maxIssuable}}</span></div>
                </div>
                <div class="col-lg-6 smaller">
                  <div class="royalties" v-if="ticketsData.ticket_royalties>0">Royalties: {{ticketsData.ticket_royalties}}%</div>
                  <div class="sevice_fee" v-if="signRoyalties>0">Service Fee: {{signRoyalties}}%</div>
                  <div class="owner" v-if="secondaryData">Seller: {{shortTxAddr(secondaryData.seller_addr)}}</div>
                  <!-- maybe get the global owner if not for sale-->
                </div>
                <div class="sell_price" v-if="secondaryData">Price: {{numberWithSpaces(secondaryData.sell_price / Math.pow(10, 8))}} SIGN</div>
              </div>
            </div>
            
            

            <div class="secondary_detail mt-4 d-flex" v-if="secondaryData">
              <ul class="links mx-auto d-flex">
                <li><a href="#" @click.prevent="chooseSigningMethod('buy')" class="cta me-md-3 mb-3 mb-lg-0">BUY TICKET</a></li>
                <li><a href="#" @click.prevent="chooseSigningMethod('bid')" class="cta offer">MAKE AN OFFER</a></li>
              </ul>
            </div>
            <div v-else class="mt-4 mb-4">
              NO FOR SALE
            </div>
            <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="text-center mt-3">
              <div class="wrap">{{responseMessage}}</div>
            </div>
          </div>
          <div class="col-12 mt-4 offers_list" v-if="ticketsData && eventData">
              <OfferList :ticket="ticketsData" :event="eventData" @bidAction="bidAction" :refreshOfferList="refreshOfferList"/>
            </div>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
import Creator from '@/components/admin/elements/EventItemCreator.vue' 
import BidWindow from '@/components/marketplace/elements/BidWindowSecondary.vue'
import bidActions from '@/components/marketplace/elements/BidActionsSecondary.vue' 
import OfferList from '@/components/marketplace/elements/OfferList.vue' 
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
export default {
  name: 'Event-page-details',
  mixins: [Global],
  data: function(){
    return {
      showNewTicketPopin: false,
      haveError:false,
      responseMessage:'',
      
      eventData: null,
      ticketsData: null,
      secondaryData: null,

      signRoyalties: 0,

      isLoadingTickets: false,
      isLoadingEvents: false,
      issueNum: this.$route.params.issueNum,
      maxIssuable: this.$route.params.maxIssuable,

      showBid: false,

      showbidData: false,
      bidData: undefined,
      
      signToUsd: null,
      bidsLoading: false,
      currentHighestBid: null,

      refreshOfferList: 0
    }
  },
  components: {
    'Creator': Creator,
    'app-bid': BidWindow,
    'app-bid-actions': bidActions,
    'OfferList': OfferList
  },
  async created(){
    this.init()
  },
  computed: {
    royalties: function(){
      return Number(this.ticketsData.ticket_royalties) + Number(this.signRoyalties)
    }
  },  
  methods:{
    init: async function(){
      await this.getEventById(this.$route.params.eventId)
      await this.getSignRoyalties()
      await this.getTicketTypeByTicketId(this.$route.params.ticketId, this.$route.params.nftId)
      await this.getSecondaryTicketData(this.$route.params.ticketId, this.$route.params.nftId)
    },  
    updateOfferList: function(){
      console.log("updateOfferList")
      this.refreshOfferList++
      console.log("this.refreshOfferList")
      console.log(this.refreshOfferList)
    },
    chooseSigningMethod: function(action){
       if(this.$store.state.user.isLogged){
         if(action == "buy"){
          this.buyArtwork()
         }
         if(action == "bid"){
           this.showBid = true
         }
       }else{
         this.$store.state.signingChoice.show = true
       }
    },
    buyArtwork: async function(){
      console.log("processing buy...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this

      if(this.$store.state.user.isLogged){
        this.responseMessage="Processing your request, please wait..."
      }
      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
        call: {
            function: "buyNFT",
            args:[
              { type:"string", value: _this.secondaryData.nftId }
            ]
        },
        payment: [
          { 
            assetId: this.$store.state.config.assetID, 
            amount: _this.secondaryData.sell_price
          }
        ]
      }
      
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          this.responseMessage=""
          if(res.error){
            // siplay error
            this.haveError = true
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Ticket successfully bought!'
            setTimeout(function(){
              _this.responseMessage = ''
              _this.init()
/*               _this.$emit('getTicketsByEventId', _this.$route.params.eventId);
              _this.$emit('updateEventTotalSold', _this.$route.params.eventId); */
              // parent reload ticket list
            }, 2000)
          }
      })
    },
    getEventById: async function(eventId){
      this.isLoadingEvents = true
      let regex = encodeUrl("^event_(.*)") 
      regex = encodeUrl("^event_(.*)_"+eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedEvents(getList)
      this.eventData = {...orderedList[0]}
      this.isLoadingEvents = false
    },
    getSignRoyalties: async function(){
      let regex = encodeUrl("^event_(.*)") 
      regex = encodeUrl("conf_signcut")
      let getCut = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_secondary}?matches=${regex}`)
      getCut = await getCut.json()
      this.signRoyalties = getCut[0].value
    },
    getTicketTypeByTicketId: async function(ticketId, nftId){
      this.isLoadingTickets = true
      let regex = encodeUrl("^ticket_(.*)") 
      if(ticketId!=''){
        regex = encodeUrl("^ticket_(.*)_"+ticketId+"|"+"^ticket_(.*)_"+ticketId+"_"+nftId)
      }
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json()
      console.log("bibou getList")
      console.log(getList)
      let orderedList = this.getOrderedTickets(getList)
      this.ticketsData = {...orderedList[0]}
      this.isLoadingTickets = false
      console.log("this.ticketsData")
      console.log(this.ticketsData)
    },
    getSecondaryTicketData: async function(ticketId, nftId){
      this.isLoadingTickets = true
      let regex = encodeUrl("^sale2nd_info_(.*)_"+ticketId+"_(.*)_"+nftId+"_OPEN")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_secondary}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.getOrderedSecondaryTickets(getList)
      this.secondaryData = orderedList[0]
      this.isLoadingTickets = false
    },
    hideBid: function(res){
      this.haveError = res.haveError
      this.responseMessage = res.responseMessage
      this.showBid = false
    },
    hideBidData: function(res){
      this.haveError = res.haveError
      this.responseMessage = res.responseMessage
      this.showbidData = false
    },
    bidAction: async function(params){

      let error = false
      let alert = "This NFT is not for sale anymore. You can reject the offer for a fee (0.005 Waves) or just wait for the offer owner to cancel it."
      let _this = this
      let [assetId, price] = this.getPriceWithTicker(params.e.currentTarget.getAttribute("data-basePrice"))

      this.bidData = {
        assetId: params.e.currentTarget.getAttribute("data-assetId"),
        basePrice: params.e.currentTarget.getAttribute("data-basePrice"),
        bidId: params.e.currentTarget.getAttribute("data-bidId"),
        bidOwner: params.e.currentTarget.getAttribute("data-bidOwner"),
        eventId: params.e.currentTarget.getAttribute("data-eventId"),
        price: price +" "+ assetId,
        type: params.type,
        nftId: params.nftid,
        saleOwner: params.e.currentTarget.getAttribute("data-saleOwner"),
        ticketId: params.e.currentTarget.getAttribute("data-ticketId"),
      }

      // check if still own NFT in case of accepting offer for an NFT you already sold
      if(params.type=='accept'){
        let regex = encodeUrl("^current_owner_on_dapp_" + params.nftid)
        let owner = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_secondary+"?matches="+regex)
        let json = await owner.json()
        if(json.length == 0 || json[0].value != this.$store.state.user.userAddress){
          error = true
        }
    }
      console.log(error)
      if(!error){
        this.haveError = false
        this.responseMessage = ""
        this.showbidData = true
      }else{
        this.haveError = true
        this.responseMessage = alert
        setTimeout(function(){
          _this.haveError=false
          _this.responseMessage=""
        }, 10000000)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .offers_list{
    margin-top: 10px;
  }
 .addTicket{
    cursor: pointer;
  }
  .ticket_detail{
    border: solid 1px white;
    border-radius: 10px;
    padding: 25px;
    .name{
      font-size: 26px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .sell_price{
      font-size: 22px;
      letter-spacing: 1px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }
  .smaller{
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  .tickets_sold{
    margin-bottom: 20px;
    font-style: italic;
  }
  .bg{
    filter: brightness(15%);
  }

  .links{
    margin-top: -47px;
  }
  @media(max-width: 992px){
    
    .ticket_detail{
      .name{
        flex-direction: column;
        align-items: flex-start!important;
      }
      .ticket_used{
        margin-left: 0!important;
        margin-bottom: 15px;
      }
    }
  }
  @media(max-width: 768px){
    .links{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
</style>
