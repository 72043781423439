<template>
  <div class="spiner" ref="spiner" v-if="showSpiner && spinerSrc">
    <img :src="spinerSrc" />
  </div>

  <figure v-lazyload class="image__wrapper w-100" :style="backgroundImage">
    <img
      ref="visuel"
      class="image__item"
      :data-srcset="imgix"
      :data-url="source"
      :data-name="altname"
      alt="Loading..."
     @load="hideSpiner();"
     @error="replaceByDefaultImg" >
  </figure>
</template>
<script>
import globalMixin from '@/mixins/global.js';
export default {
  mixins: [globalMixin],
  name: "ImageItem",
  props: {
    source: {
      type: String,
      required: true
    },
    altname: {
      type: String,
      required: true
    },
    imgix: {
      type: String
    }
  },
  data: function(){
    return {
      showSpiner: true,
      backgroundImage: ''
    }
  },
/*   mounted(){
    console.log("this.$refs")
    console.log(this.$refs.visuel)
  }, */
  methods: {
    imgixLast: function(){
      if(this.imgix.length){
        let last = this.imgix.split(',')
        last = last[last.length-1].split(" ")
        this.backgroundImage = `background: url(${last[0]}); background-repeat:no-repeat; background-size: 400%, 400%; background-position: center, center;`
      }
    },
    hideSpiner: function(){
      let _this = this
      if(this.$refs.spiner){
        this.showSpiner = false
        setTimeout(function(){
          _this.imgixLast()
        }, 1000)
        
      }
    }
  },
  beforeUnmount(){
    let _this=this
    _this.$refs.visuel.srcset = _this.emptySrc
    _this.$refs.visuel.src = _this.emptySrc
  }
};
</script>
<style scoped lang="scss">
figure{
  margin-bottom: 0;
}
.spiner{
  img{
    width: auto!important;
  }
}
body.print{
  .image__wrapper:after {
    opacity: 0!important;
  }
}
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    &:after{
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.8;
      position: absolute;
      z-index: 1;
    }

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
          //height: 100%;
          object-fit: cover;
          //box-shadow: 0px 0px 50px 50px rgba(0,0,0,0.9);
          z-index: 2;
        }

        &__spinner {
          display: none;
          width: 100%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    //visibility: hidden;
  }
}
</style>
