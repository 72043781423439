
import {libs} from '@waves/waves-transactions';
import { fileTypeFromBlob } from "file-type-browser";
export default {
  data: function() {
    return {
      emptyThumb: require("@/assets/images/thumbnail.png"),
      emptySrc: require("@/assets/images/no_display.svg"),
      loaderSrc: require("@/assets/images/loader.svg"),
      spinerSrc: require('@/assets/images/spiner.svg'),
      mp4Alert: false,
    };
  },
  methods: {
      replaceByDefaultImg: function(image){
        image.target.src = this.emptySrc
      },
      base10ToDecimal: function(amount, decimal){
        return amount / Math.pow(10, decimal)
      },
      decimalToBase10: function(amount, decimal){
        return amount * Math.pow(10, decimal)
      },
      trim: function(value, length) {
        if(value){
          let max = length ? length : 150
          let dots = value.length > length ? "..." : ""
          if (!value) return "";
          value = value.substr(0, max);
          
          return value + dots;
        }else{
          return
        }
      },
      getCurrentHeight: async function(){
        let currentHeight = await fetch(this.$store.state.config.nodeURL+"/blocks/height")
        currentHeight = await currentHeight.json()
        return currentHeight.height
      },
      setDate: function(value) {
        if (!value) return "";
        value = new Date(Number(value));
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let year = value.getFullYear();
        let month = months[value.getMonth()];
        let date = value.getDate();
        let time = date + " " + month + " " + year;
        return time;
      },
      setTime: function(value) {
        if (!value) return "";
        value = new Date(Number(value));
        let time = value.getHours() +":"+ (value.getMinutes()<10?'0':'') + value.getMinutes()
        return time;
      },
      setDateTime: function(value){
        if (!value) return "";
        value = new Date(Number(value));
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let year = value.getFullYear();
        let month = months[value.getMonth()];
        let date = value.getDate();
        let time = date + " " + month + " " + year + " at "+value.getHours() +":"+ (value.getMinutes()<10?'0':'') + value.getMinutes();
        return time;
      },
      goToAccount: function(){
        let _this = this
        if(_this.$router.currentRoute.name == "admin-profile"){ return } 
        _this.$router.push("/admin/profile/" + _this.$store.state.user.userAddress)
      },
      goToHome: function(){
        let _this = this
        if(_this.$router.currentRoute.name == "home"){ return } 
        console.log("should go home now...")
        _this.$router.push({ name: `home` })
      },
      closeResponseMessage: function(){
        this.haveError = false
        this.responseMessage = ""
      },
      progress: function(data){ // adding to ipfs
        let completedPercentage = parseInt( ( data.loaded / data.total ) * 100 );
        if(document.getElementById("msg-layer").querySelector(".progress-txt")){
          if(!data.addingToIpfs){
            document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = completedPercentage+"%"
            document.getElementById("msg-layer").querySelector(".progress-bar").style.width = completedPercentage+"%"
          }else{
            document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = "Adding file to IPFS, please wait..."
            document.getElementById("msg-layer").querySelector(".progress-bar").style.width = "100%"
          }
        }
      },
      isAddress: function(addr){
        let currentNetwork = this.$store.state.config.network == "mainnet" ? 87 : 84
        return libs.crypto.verifyAddress(addr, {chainId: currentNetwork})
      },
      getExplorerUrl: function(){
        if(this.$store.state.config.network == "testnet"){
          return 'https://'+this.$store.state.config.network+'.'+this.$store.state.config.wavesExplorer
        }else{
          return 'https://www.'+this.$store.state.config.wavesExplorer
        }
      },
      getIpfsLink: function(string) {
        if(string!="" && string!=undefined){
          let cid_name = string.split("/");
          return "https://" +this.$store.state.config.ipfsGateway +"/"+encodeURIComponent(cid_name[0]) +"/" + encodeURIComponent(cid_name[1]);
        }else{
          return ""
        }
      },
      numberWithSpaces: function (x) {
        let resultat = ""
        if(x){
          if(x.toString().includes(".")){
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            resultat = parts.join(".");
          }else{
            resultat = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          
          }
        }
        return resultat == "" ? 0 : resultat 
      },
      copyString: async function(str, e) {
        e.target.parentNode.querySelector(".copied").classList.add("show")
        e.target.parentNode.querySelector(".copied").innerHTML = "Please wait...";
        try {
          await navigator.clipboard.writeText(str);
          e.target.parentNode.parentNode.querySelector(".copied").innerHTML = "copied!";
          setTimeout(function() {
            e.target.parentNode.querySelector(".copied").classList.remove("show")
            e.target.parentNode.parentNode.querySelector(".copied").innerHTML = "";
          }, 2000);
        } catch (err) {
          console.log(err)
          e.target.parentNode.querySelector(".copied").classList.remove("show")
          e.target.parentNode.querySelector(".copied").innerHTML = "";
        }
      },

      getCurrentUrl: function(){
        this.currentURL = window.location.origin + this.$route.path      
      },
      getFileType: async function(event){
        let fileType = await fileTypeFromBlob(event.target.files[0]);
        return fileType
      },

      getFileExtension: function(fileName){
        console.log("fileName")
        console.log(fileName)
        let regex = new RegExp('[^.]+$');
        let extension = fileName.match(regex);
        return extension[0]
      },

      shortTxAddr: function(str, len=7){
        let getLeft = str.substring(0, len)
        let getRight = str.substring(str.length-len)
        return getLeft+"..."+getRight
      },
      fileDimensionCrawler:  async function(type, event){
        this.crawler.error = false
        this.crawler.cid = ''
        this.crawler.label = "Select the crawler file"
        this.crawler.imageToUpload = null
        let _this = this
        let readerImage = new FileReader();
        _this.crawler.dimensionError = false
        _this.crawler.label = "Checking file..."
        
        if (event.target.files[0].type.match('image.*')) {
          readerImage.readAsDataURL(event.target.files[0]);
          readerImage.onload = e => {
            let image = new Image();
            image.src = e.target.result;
            image.onload = function() {
              if(event.target.files[0].size > _this.crawler.maxsize){
                _this.crawler.label = "Max size allowed: "+ _this.crawler.maxsize/1000000+"Mb"
                _this.crawler.dimensionError = true
              }else{
                _this.crawler.label = event.target.files[0].name
                _this.crawler.imageToUpload = event
              }
            };
          }
        }else{
          _this.crawler.label = `Only ${_this.$store.state.acceptedFiles.crawler.extImg.toString()} accepted at the moment`
          _this.crawler.dimensionError = true
        }
      },
      fileDimension:  async function(type, event){
        
        this.crawler.cid = ''
        this.crawler.label = "Select the crawler file"
        this.crawler.imageToUpload = null
        this.display.isMP4 = false
        this.display.error = false
        this.mp4Alert = false

        this.haveError=false;
        this.dimensionError=''

        let _this = this
        let readerImage = new FileReader();
        _this.display.dimensionError = false
        _this.display.label = "Checking file..."

        if (event.target.files[0].type.match('image.*')) {
          readerImage.readAsDataURL(event.target.files[0]);
          readerImage.onload = e => {
            
            let image = new Image();
            image.src = e.target.result;

            image.onload = function() {
              if(image.width > 768 || image.height > 992){
                let useWidth
                if(image.width>image.height){
                  useWidth = true
                }else if(image.with < image.height){
                  useWidth= false
                }else{
                  useWidth = true
                }
                if(event.target.files[0].type != 'image/gif'){
                  _this.resizeImgage(event.target.files[0], useWidth, type)
                }else{
                _this.display.label = "Animated GIF:<br/> 768 x 992px max"
                _this.display.dimensionError = true
                }
              }else{
                  if(event.target.files[0].type == 'image/gif'){ // GIF MAXSIZE = 2x display maxsize 
                    if(event.target.files[0].size > _this.display.maxsizeGif){
                      _this.display.label = "Max GIF size: "+ _this.display.maxsizeGif/1000000+"Mb"
                      _this.display.dimensionError = true
                    }
                  }else{
                    if(event.target.files[0].size > _this.display.maxsize){
                      _this.display.label = "Max size allowed: "+ _this.display.maxsize/1000000+"Mb"
                      _this.display.dimensionError = true
                    }
                  }
              }
              return
            };
          }
        }else if(event.target.files[0].type.match('video.*')){
          console.log("matched video")
          /* let metadata = await this.getFileType(event)
          if(!this.$store.state.acceptedFiles.display.accept.includes(metadata.mime)){
            this.display.label = "Wrong file format"
            this.display.error = true
            return
          }
          if(metadata.mime == "video/mp4"){
            this.mp4Alert = true
          }else{
            this.mp4Alert = false
          }
          if(event.target.files[0].size > this.display.maxsize){
            this.display.label = "File is too big"
            this.display.error = true
            return
          }
          this.display.isMP4 = true */
          this.display.label = "Video not supported."
          this.display.dimensionError = true
          
          return
        }else{
          if(type=="display"){
            _this.display.label = `Only ${_this.$store.state.acceptedFiles.display.extImg.toString()} accepted at the moment`
            _this.display.dimensionError = true
            return
          }
          if(type=="crawler"){
            _this.crawler.label = `Only ${_this.$store.state.acceptedFiles.crawler.extImg.toString()} accepted at the moment`
            _this.crawler.dimensionError = true
            return
          }
        }
        this[type].imageToUpload = event.target.files[0]
        this.display.label = ""
        console.log("hihi")
        console.log(this.display.imageToUpload)
      },
      resizeImgage: function(image, useWidth) {
        let _this = this
        const width = 768;
        const height = 992;
        const fileName = image.name;
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = event => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const elem = document.createElement('canvas');
                let scaleFactor;
                if(useWidth){
                  scaleFactor = width / img.width;
                  elem.width = width;
                  elem.height = img.height * scaleFactor;
                }else{
                  scaleFactor = height / img.height;
                  elem.height = height;
                  elem.width = img.width * scaleFactor;
                }

                const ctx = elem.getContext('2d');
                // img.width and img.height will contain the original dimensions
                if(useWidth){
                  ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
                }else{
                  ctx.drawImage(img, 0, 0, height, img.width * scaleFactor);
                }
                // 'image/jpeg'
                ctx.canvas.toBlob(async (blob) => {
                    const file = new File([blob], fileName, {
                        type: image.type,
                        lastModified: Date.now()
                    });
                    if(file.size > _this.display.maxsize){
                      _this.display.label = "File is too big"
                      _this.display.dimensionError = true
                    }
                }, image.type, 1);
              },
              reader.onerror = error => console.log(error);
        }
      },
      getPriceWithTicker: function(price){ // assetId and non decimal price 100000000
        console.log("iciiiiiiiiiiiiiiiiiiiiii price")
        console.log(price)
        let ticker = "SIGN"
        let newPrice = price / Math.pow(10,8)
              
        return [ticker, newPrice, newPrice + " " +ticker]
      },
      throwError: function(msg){
        this.responseMessage = msg
        this.haveError = true
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
      },
      checkMandatoryFields: function(){
        let display = document.getElementById("displayFile");
        let crawler = document.getElementById("crawlerFile");

        this.haveError = false;
        this.responseMessage = ""

        if (this.display.isMP4 && this.crawler.cid == '' && !crawler.files[0]) {
          this.haveError = true;
          this.responseMessage = "Please add a thumbnail image if you use a video as preview";
          return;
        }

        if (!display.files[0] && this.display.cid == '') {
          this.haveError = true;
          this.responseMessage = "Please add a preview image";
          return;
        }

        if (!this.imtheowner) {
          this.haveError = true;
          this.responseMessage =
            "Please confirm that your flyer don't go against our terms.";
          return;
        }

        if (this.name == "") {
          this.haveError = true;
          this.responseMessage = "Please enter a title";
          return;
        }
        if (this.type == "") {
          this.haveError = true;
          this.responseMessage = "Please select an event type";
          return;
        }
        if (this.date == "") {
          this.haveError = true;
          this.responseMessage = "Please enter an event date";
          return;
        }

        if (this.description == "") {
          this.haveError = true;
          this.responseMessage = "Please describe your event";
          return;
        }
      },
      checkUploadFiles: async function(){

        this.haveError = false;
        this.responseMessage = "";
        let addCrawlerToIpfs, addDisplayToIpfs

        this.$store.state.alert=true
        this.$store.state.alertMessage='Sending your files to IPFS, Please wait...'

        if(this.crawler.dimensionError){
          this.throwError("Crawler Image should be 100Kb max")
          return
        }

        if(this.crawler.imageToUpload){
          let ext = this.getFileExtension(this.crawler.imageToUpload.name)
          let new_crawler = new File([this.crawler.imageToUpload], 'crawler.'+ext);

          if(ext.toLowerCase() == "gif"){
            this.throwError("Crawler GIF no accepted")
            return
          }

          if(new_crawler.size > this.crawler.maxsize){
            this.throwError("Display Image is too big, "+(this.crawler.maxsize/1000000)+"Mb maximum")
            return
          }

          this.$store.state.alertMessage = "Sending your crawler file: <strong>"+this.crawler.imageToUpload.name+"</strong>";
          document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
          addCrawlerToIpfs = await this.$store.state.ipfs.upload(new_crawler, this.progress)
          document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
          if(!addCrawlerToIpfs.error){
            this.crawler.cid = addCrawlerToIpfs.cid.string+"/"+new_crawler.name
          }else{
            this.throwError(addCrawlerToIpfs.error)
            return
          }
        }

        if(this.display.dimensionError){
          this.throwError("Display should be an Image of 1024x1500px max")
          return
        }

        if(this.display.imageToUpload){
          console.log("this.display.imageToUpload")
          console.log(this.display.imageToUpload)
          let ext = this.getFileExtension(this.display.imageToUpload.name)
          let new_display = new File([this.display.imageToUpload], 'display.'+ext);

          if(ext.toLowerCase() == "gif" && new_display.size > this.display.maxsizeGif){
            this.throwError("Display Image is too big, "+(this.display.maxsizeGif/1000000)+"Mb maximum")
            return
          }

          if(ext.toLowerCase() != "mp4" && ext.toLowerCase() != "gif" && new_display.size > this.display.maxsize){
            this.throwError("Display Image is too big, "+(this.display.maxsize/1000000)+"Mb maximum")
            return
          }

          this.$store.state.alertMessage = "Sending your display file: <strong>"+this.display.imageToUpload.name+"</strong>";
          document.getElementById("msg-layer").querySelector(".progress").classList.add("show")
          addDisplayToIpfs = await this.$store.state.ipfs.upload(new_display, this.progress)
          document.getElementById("msg-layer").querySelector(".progress").classList.remove("show")
          if(!addDisplayToIpfs.error){
            this.display.cid = addDisplayToIpfs.cid.string+"/"+new_display.name
          }else{
            this.throwError(addDisplayToIpfs.error)
            return
          }
        }

        return
      },
      getOrderedEvents: function(data){
        let newData = Object.values(data).reduce((acc, curr) => {
          const _key = curr.key;
          let getAddress = _key.substr(_key.lastIndexOf("_")+1, 35);
          let getId = _key.substr(0, _key.lastIndexOf("_"));
          getId = getId.substr(getId.lastIndexOf("_")+1, 44);
          const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
          const getKeyWithoutTxid = getKeyWithoutAddr.substr(0, getKeyWithoutAddr.lastIndexOf("_"));

          if (!acc[getId]) {
            acc[getId] = [];
          }
          acc[getId]["" + getKeyWithoutTxid] = curr.value;
          acc[getId].user_addr = getAddress
          acc[getId].txid = getId
            
          return acc;
        }, {});

        let orderedData = Object.values(newData).sort(function(a,b){
          return new Date(b.event_addedDate) - new Date(a.event_addedDate);
        });

      return orderedData
      },
      getOrderedTickets: function(data){
        let newData = Object.values(data).reduce((acc, curr) => {
          const _key = curr.key.split("_");
          let getAddress = _key[3];
          let getId =  _key[4];
          const getKeyWithoutTxid =  _key[0]+"_"+ _key[1];
          if(getKeyWithoutTxid != "ticket_sold"){
            if (!acc[getId]) {
              acc[getId] = [];
            }
            acc[getId]["" + getKeyWithoutTxid] = curr.value;
            acc[getId].user_addr = getAddress
            acc[getId].txid = getId
          }
            
          return acc;
        }, {});

        let orderedData = Object.values(newData).sort(function(a,b){
          return new Date(b.ticket_date) - new Date(a.ticket_date);
        });

      return orderedData
      },
      getTicketSoldData: function(data){
        let newData = Object.values(data).reduce((acc, curr) => {
          const _key = curr.key.split("_");

            if (!acc[_key[8]]) {
              acc[_key[8]] = [];
            }

            acc[_key[8]].issue_num = _key[2]
            acc[_key[8]].max_issuable = _key[4]
            acc[_key[8]].ticketId = _key[5]
            acc[_key[8]].eventId = _key[6]
            acc[_key[8]].organizer = _key[7]
            acc[_key[8]].nftId = _key[8]
          
            
          return acc;
        }, {});

        let orderedData = Object.values(newData).sort(function(a,b){
          return new Date(b.ticket_date) - new Date(a.ticket_date);
        });

      return orderedData
      },
      getOrderedSecondaryTickets: function(data){
        let newData = Object.values(data).reduce((acc, curr) => {
          const _key = curr.key.split("_");
          console.log("_key in getOrderedSecondaryTickets")
          console.log(_key)
          
          const _value = curr.value.split("_");
          let ticketId =  _key[3];
          let saleId = _value[7]

            if (!acc[saleId]) {
              acc[saleId] = [];
            }
            acc[saleId].seller_addr = _key[2]
            acc[saleId].ticketId = ticketId
            acc[saleId].nftId = _key[6]
            acc[saleId].eventId = _key[4]
            acc[saleId].organizer_addr = _key[5]
            acc[saleId].timestamp = _value[0]
            acc[saleId].sell_price = _value[1]
            acc[saleId].sell_asset = _value[2]
            acc[saleId].txid = _value[7]
          
            
          return acc;
        }, {});

        let orderedData = Object.values(newData).sort(function(a,b){
          return new Date(b.timestamp) - new Date(a.timestamp);
        });

      return orderedData
      },
      
  },
};