<template>           

    <div class="loadingText" v-if="!isLoaded">
      Loading ticket detail...
    </div>

    <div v-else class="container ticket_print" :id="'test_'+index">
      <div class="row" >
        <div class="col-lg-3 flyer">
          <router-link :to="`/event/${nft.txid}`">
            <imageLoader 
                :source="nft.event_displayCid ? getIpfsLink(nft.event_displayCid) : emptySrc" 
                :imgix="proxyDisplay  ? proxyDisplay : nft.event_displayCid" 
                :altname="nft.event_name" 
              />
          </router-link>
        </div>
        <div class="col-lg-6 content">
          <div v-if="nft.event_canceled">CANCELED <p>{{nft.event_canceledReason}}</p></div>
          <h2>{{nft.event_name}}</h2>
          <div class="date">Event date: {{ nft.event_date }}</div>
          <div class="desc">{{ trim(nft.event_desc, 150)}}</div>

          <div class="tickets_details" v-if="nft.user_addr">
            <Creator :user_addr="nft.user_addr" class="w-100 mb-3" data-html2canvas-ignore/>
            <div class="tickets_sold w-100 mb-2">Ticket name: <span>{{ticket.ticket_name}}</span></div> 
            <div class="tickets_sold">Ticket reference: <span>{{ qrURL }}</span></div> 
            <div class="tickets_sold">Ticket price: <span>{{numberWithSpaces(base10ToDecimal(ticket.ticket_price, 8))}} SIGN</span></div> 
            <div class="tickets_sold">Ticket used: <span>{{ usedAmount }} of {{ ticket.ticket_maxUse }}</span></div> 
          </div>
          
        </div>
        <div class="col-lg-3 print_qr d-flex flex-column justify-content-center align-items-center position-relative pb-3 pb-lg-0">
          <transition name="fade">
            <div class="sellPopin" v-if="showSellPopin" data-html2canvas-ignore>
              <div class="closeSellPopin" @click="showSellPopin=false"><font-awesome-icon icon="fa-solid fa-xmark" /></div>
              <input type="number" class="form-control mb-3" placeholder="Enter amount in SIGN" v-model="sellPrice"/>
              <button class="btn btn-primary" @click="setForSale()">CONFIRM</button>
            </div>
          </transition>

          <transition name="fade">
            <div class="sellPopin" v-if="showTransferPopin" data-html2canvas-ignore>
              <div class="closeSellPopin" @click="showTransferPopin=false"><font-awesome-icon icon="fa-solid fa-xmark" /></div>
              <input type="text" class="form-control mb-3" placeholder="Enter recipient waves address" v-model="recipient"/>
              <div class="mb-3 pointer-events-auto w-100">
                <label :for="'attachment_'+nftInfo.name">Message:</label>
                <textarea class="form-control attachment" :id="'attachment_'+nftInfo.name" v-model="attachment" placeholder="140 Characters maximum"></textarea>
              </div>
              <button class="btn btn-primary" @click="transferNFT()">CONFIRM</button>
            </div>
          </transition>

          <div :class="{error: haveError}" v-show="responseMessage!=''" class="response col-xl-10 mx-auto text-center mt-3 position-absolute" data-html2canvas-ignore>
            <div class="closeResponse" @click="responseMessage=''"><font-awesome-icon icon="fa-solid fa-xmark" /></div>
            <div class="wrap">{{responseMessage}}</div>
          </div>

          <QRCodeVue3
            :value="qrURL"
            :width="150"
            :height="150"
            :qrOptions="{ typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' }"
            :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
            :dotsOptions="{ type: 'square', color: '#05004d' }"
            :cornersSquareOptions="{ type: 'square', color: '#0e013c' }"
          />

          <div class="mt-4 d-flex flex-wrap justify-content-center" :class="{'flex-row':forsale=='NOTFORSALE', 'flex-column': forsale=='FORSALE'}" v-if="forsale=='FORSALE' || forsale=='NOTFORSALE'">
            <div class="price" v-if="forsale=='FORSALE'">Sell price: <span>{{numberWithSpaces(base10ToDecimal(nftInfo.price, 8))}} SIGN</span></div>
            <button class="btn btn-primary mb-3 mb-lg-2" v-if="forsale=='FORSALE'" @click="cancelSale()" data-html2canvas-ignore>CANCEL SALE</button>
            <button class="btn btn-primary mb-3 mb-lg-2 me-2" v-if="forsale=='NOTFORSALE'" @click="showSellPopin=true" data-html2canvas-ignore>SELL</button>
            <button class="btn btn-primary mb-3 mb-lg-2 me-2 me-lg-0" v-if="forsale=='NOTFORSALE'" @click="showTransferPopin=true" data-html2canvas-ignore>TRANSFER</button>
            <button class="btn btn-primary mb-3 mb-lg-0 me-2 me-lg-0"  v-if="forsale=='NOTFORSALE'" @click="printPdf()" data-html2canvas-ignore>DOWNLOAD</button>
          </div>

        </div>
      </div>
    </div>

</template>
<script>
import Global from '@/mixins/global.js';
import encodeUrl from 'encodeurl';
import html2pdf from 'html2pdf.js';
import imageLoader from '@/components/elements/ImageItem.vue' 
import Creator from '@/components/admin/elements/EventItemCreator.vue' 
import QRCodeVue3 from "qrcode-vue3";
export default {
  mixins: [Global],
  props: ["nftInfo", "index", "updateCount", "forsale", "removeNFT"],
  data: function(){
    return {
      nft: [],
      ticket: [],
      usedAmount: 0 ,
      issueNumber: null,
      maxissuable: null,
      isLoaded: false,
      haveError:false,
      responseMessage: "",
      proxyDisplay: null,
      qrURL: this.nftInfo.name,
      showSellPopin: false,
      showTransferPopin: false,
      sellPrice: null,
      recipient: "",
      attachment: ''
    }
  },
  components:{
    Creator,
    QRCodeVue3,
    imageLoader
  },
  created(){
    this.init()
  },
  watch:{
    "updateCount": function(){
      this.usedAmount++
    }
  },
  methods: {
    init: async function(){
      await this.getNFTinfo()
      this.setAmountUsed()
      this.qrURL = this.nftInfo.name ? this.nftInfo.name : await this.getNFTName()
    },
    printPdf: function(){
    
    
    var element = document.getElementById('test_'+this.index);

    var opt = {
          margin: 6,
          filename: 'ticket_'+this.qrURL+'.pdf',
          image: { type: 'png', quality: 1 },
          html2canvas: { scale: 1, useCORS: true },
          jsPDF: { orientation: 'landscape', unit: 'mm', format: [297, 210] }
    };
    
    var source = document.querySelector('body');
    source.classList.add('print');
    html2pdf().set(opt).from(element).save().then(function(){
    source.classList.remove('print');
});
      //html2pdf(element, opt);
    },
    transferNFT: async function(){
      let _this = this
      _this.responseMessage = "Transfer in progress..."
      //let transferTx
      /* if(this.$store.state.signingChoice.keeper){
        transferTx = {amount: 1, recipient: _this.recipient, assetId: _this.nftInfo.assetId, attachment: _this.attachment, feeAssetId: "WAVES", fee: 0.001}
      }else{ */
        const attachment_base58 = _this.$store.state.cryptolibs.base58Encode(_this.$store.state.cryptolibs.stringToBytes(_this.attachment));
        let transferTx = {amount: 1, recipient: _this.recipient, assetId: _this.nftInfo.assetId, attachment: attachment_base58}
      //}

      _this.$store.dispatch("signerTransfer", {data:transferTx, isNFT: true}).then(res => { 
        if(res.error){
          if(res.message){
             _this.responseMessage = res.message
          }else{
           _this.responseMessage = res.error
          }
          _this.haveError=true
        }else{
          _this.responseMessage = "NFT Transfered"
          _this.$parent.$emit('removeNFT', _this.nftInfo.assetId)
          _this.haveError=false
        }
      })
    }, 
    cancelSale: async function(){
      let _this = this
      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
        call: {
            function: "cancelSale",
            args:[
              { type:"string", value: this.nftInfo.assetId }
          ]
        }
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.mp4Alert = false
            this.responseMessage = 'Ticket successfully removed!'
            this.$emit("removeNFT", _this.nftInfo.assetId)
            setTimeout(function(){
              _this.responseMessage = ''
            }, 3000)
          }
      })
    },
    setForSale: async function(){
      let _this = this
      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_secondary,
        call: {
            function: "sellNFT",
            args:[
              { type:"integer", value: this.sellPrice * Math.pow(10, 8) }
          ]
        }
      }
      dataTx.payment = [{ assetId: this.nftInfo.assetId, amount: 1 }]

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            let resultError = res.error.message
            if(res.error.message.includes("restrincted")){
              let msgArray = res.error.message.split(":")
              resultError = msgArray[1]+ ": "+ new Date(parseInt(msgArray[2]))
            }
            this.throwError(resultError)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.mp4Alert = false
            this.responseMessage = 'Ticket successfully added!'
            _this.$emit('removeNFT', _this.nftInfo.assetId)
            setTimeout(function(){
              _this.responseMessage = ''
            }, 3000)
          }
      })
    },
    getNFTName: async function(){
      let getNFTdata = await fetch(`${this.$store.state.config.nodeURL}/assets/details/${this.nftInfo.assetId}`)
      getNFTdata = await getNFTdata.json()
      return getNFTdata.name
    },
    setAmountUsed: async function(){
      let regex = encodeUrl("^ticket_amountUsed_"+this.nft.txid+"_(.*)_"+this.ticket.txid+"_"+this.nftInfo.assetId)
      let amountUsed = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      amountUsed = await amountUsed.json()  
      this.usedAmount = amountUsed[0] ? amountUsed[0].value : 0
    },  
    getProxyImage: async function(cid){
      let imgixData =[{id: null, url: this.getIpfsLink(cid)}]
      let response = await fetch(this.$store.state.config.signArtApiURL+"/imgix", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify(imgixData)
        });
        const imgix = await response.json();
      return imgix.res[0].url
    },
    getNFTinfo: async function(){
      let _this =this
      let regex, eventId, organizerAddr
      try{
        console.log("getNFTinfo")
        console.log(this.nftInfo)
        if(this.nftInfo.originTransactionId){
          let originTx = await fetch(this.$store.state.config.nodeURL+"/transactions/info/"+this.nftInfo.originTransactionId)
          originTx = await originTx.json()
          originTx = originTx.payload ? originTx.payload : originTx
          eventId = originTx.call.args[1].value
          organizerAddr = originTx.call.args[2].value
          
        }else{
          eventId = this.nftInfo.eventId
          organizerAddr = this.nftInfo.organizerAddr
        }

        regex = encodeUrl("^event_(.*)_"+ eventId +"_"+ organizerAddr)
        
        let eventInfo = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_events+"?matches="+regex)
        eventInfo = await eventInfo.json()
        let eventFiltered = this.getOrderedEvents(eventInfo)
        if(eventFiltered[0]){
          this.nft = eventFiltered[0]
          this.nft.user_addr = organizerAddr
        }
        this.proxyDisplay = await this.getProxyImage(eventFiltered[0].event_displayCid)
        

        let getIssueNumber = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_events+"?matches=nft_"+this.nftInfo.assetId)
        getIssueNumber = await getIssueNumber.json()
        
        let splittedValue = getIssueNumber[0].value.split("_")
        _this.issueNumber = splittedValue[2]+" of "+splittedValue[4]
        regex = encodeUrl("^ticket_(.*)_"+ splittedValue[6] +"_"+ splittedValue[7]+ "_" +splittedValue[5])
        let getTicketDetails = await fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.$store.state.config.dapp_addr_events+"?matches="+regex)
        getTicketDetails = await getTicketDetails.json()
        let ticketFiltered = this.getOrderedTickets(getTicketDetails)
        if(ticketFiltered[0]){
          this.ticket = ticketFiltered[0]
        }

        this.isLoaded = true
        // this.getCurrentUrl()
      }catch( err ){
         console.log(err) 
      }
    }
  }
}
</script>
<style lang="scss" scoped>
h2{
  font-size: 35px!important;
}
.sellPopin{
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border-radius: 0 19px 19px 0;
  bottom: 0;
  .closeSellPopin{
    color: white;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
.fa-xmark{
  color: white;
}
.print_qr{
  border-left: dashed 2px black;
}
.price{
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 1px;
  span{
    font-weight: 600;
  }
}
body.print .ticket_print{
  border: solid 1px rgb(210, 210, 210)!important;
  border-radius: 20px!important;
  .flyer{
    overflow: hidden;
    border-radius: 20px 0 0 20px!important;
    img{
      overflow: hidden;
      border-radius: 20px 0 0 20px!important;
    }
  }
}

.ticket_print{
  //border: solid 1px #ddd;
  .flyer{
    max-height: 360px!important;
    overflow: hidden!important;
    //border: solid 1px #bbb;
    padding: 0!important;
    img{
      width: 100%!important;
      height: 100%!important;
      -o-object-fit: cover!important;
      object-fit: cover!important;
      display: block!important;
      max-width: 100%!important;
      opacity:1!important;

    }
    @media (max-width: 1200px) and (min-width: 992px){
      max-height: 460px!important;
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 35px;
    font-size: 16px;
    .date{
      font-weight: bold;
      margin-bottom: 10px;
    }
    .tickets_details{
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .tickets_sold{
        margin-right: 20px!important;
        &:last-child{
          margin-right: 0;
        }
        span{
          font-weight: bold!important;
        }
      }
      .organizer a {
        text-decoration: none;
        color: #16a1ab;
        display: flex;
        align-items: center;
        padding-top: 2px;
        font-size: 16px;
        img{
          width: 30px;
          height: 30px;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
    .btn-primary{
      background-color: #09484c;
      border-color: #09484c;
      border-radius: 50px;
      padding-left: 20px;
      padding-right: 20px;
      color: white;
    }
  }
}
@media(max-width: 992px){
  .print_qr{
    border-left: dashed 0px black;
  }
  .sellPopin{
    height: auto;
    min-height: 100%;
    padding-top: 40px;
    border-radius: 0 0 19px 19px;
  }
}
</style>
