<template>
  <div class="page popin" v-if="show">

    <font-awesome-icon icon="fa-solid fa-xmark" @click="closeNewTicketPopin()"/>
    <div id="createEvent">
      <h3 class="text-center">{{getTitle()}}</h3>
      <form>
        <div class="mb-4">
          <label for="ticketName" class="form-label">Ticket name:</label>
          <input type="text" class="form-control" id="ticketName" v-model="name">
        </div>
        <div class="mb-4">
          <label for="ticketDesc" class="form-label">Ticket description:</label>
          <textarea type="text" class="form-control" id="ticketDesc" v-model="description"></textarea>
        </div>       
        
        <div class="row">
          <div class="mb-4 col-lg-6">
            <label for="ticketPrice" class="form-label">Ticket price in SIGN:</label>
            <input type="number" class="form-control" id="ticketPrice" v-model="price">
          </div>
          <div class="mb-4 col-lg-6">
            <label for="ticketRoyalties" class="form-label">Resell % royalties:</label>
            <input type="number" class="form-control" id="ticketRoyalties" v-model="royalties">
          </div>
        </div>
        <div class="row">
          <div class="mb-4 col-lg-6">
            <label for="ticketAmount" class="form-label">Ticket max available:</label>
            <input type="number" class="form-control" id="ticketAmount" v-model="maxAvailable">
          </div>
          <div class="mb-4 col-lg-6">
            <label for="ticketAmount" class="form-label">Ticket max usage:</label>
            <input type="number" class="form-control" id="ticketAmount" v-model="maxUse">
          </div>
        </div>

        <div class="row">
          <div class="mb-4 col-12">
            <label for="ticketresellLimitation" class="form-label">Ticket resell limitation untill:</label>
            <date-picker v-model="resellLimitation" :disabled="nolimit" valueType="timestamp" type="datetime" minimumView="time" inputFormat="yyyy-MM-dd HH:mm" @clear="clearDatePickerStart" :lower-limit="lowerLimit()" :editable="false" :disabledDates="{ predicate: disableDate }" :disabledTime="{ predicate: disableTime }" :show-second="false" :default-value="lowerLimit()" class="d-block form-control"></date-picker>
            <div class="mention d-flex">
              <input type="checkbox" v-model="nolimit" id="nolimit" class="me-2"/> <label for="nolimit">No limitation</label>
            </div>
          </div>
        </div>

        <div :class="{error: haveError}" v-show="responseMessage!=''" class="response col-xl-10 mx-auto text-center mt-3">
          <div class="wrap">{{responseMessage}}</div>
        </div>
        <button class="btn btn-primary d-block mx-auto" @click.prevent="processAdd">{{getTitle()}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import Global from '@/mixins/global.js';
import DatePicker from 'vue3-datepicker';

let initialState = {
      name: "",
      description: "",
      price: 0,
      maxAvailable: 0,
      maxUse: 1,
      royalties: 3,
      responseMessage: '',
      haveError: false,
      resellLimitation: null,
      nolimit: true
    }
export default {
  name: 'New-Ticket',
  props: ['show','closeNewTicketPopin','ticketToEdit'],
  mixins: [Global],
  data: function(){
    return initialState
  },
  components: {
    'date-picker': DatePicker
  },
  created(){
    this.resellLimitation = this.lowerLimit()
  },  
  beforeUnmount(){
    document.querySelector("body").style.overflowY = "auto"
  },
  methods: {
    resetWindow: function(){
      this.name = ""
      this.description = ""
      this.price = 0
      this.maxAvailable = 0
      this.resellLimitation = this.lowerLimit()
    },
    getTitle: function(){
      return this.ticketToEdit && this.ticketToEdit.ticket_name ? "EDIT TICKET" : "ADD A NEW TICKET"
    },

    disableDate: function(date){
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    disableTime: function(date){
      return date.getTime() < new Date().getTime() 
    },
    lowerLimit: function(){
      return new Date(new Date().getTime())
    },

    clearDatePickerStart: function(){
      this.resellLimitation = null
    },

    processAdd: async function(){
      console.log("processing add...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this
      
      if(this.name == ""){
        this.haveError = true
        this.responseMessage = "Name mandatory."
      }
      if(this.description == ""){
        this.haveError = true
        this.responseMessage = "Description mandatory."
      }
      if(this.price <= 0){
        this.haveError = true
        this.responseMessage = "Sell price mandatory."
      }
      if(this.maxAvailable == 0){
        this.haveError = true
        this.responseMessage = "Max tickets available mandatory."
      }
      if(this.maxUse <= 0){
        this.haveError = true
        this.responseMessage = "Maximum usage per ticket mandatory."
      }

      if(this.resellLimitation == null){
        this.haveError = true
        this.responseMessage = "Date start is wrong."
      }

      if(this.royalties < 0){
        this.haveError = true
        this.responseMessage = "Royalties cannot be negative."
      }

      if(this.haveError){
        this.$store.state.alert=false
        this.$store.state.alertMessage=''
        return
      }

      let functionToCall = this.ticketToEdit && this.ticketToEdit.ticket_name ? "editTicket" : "addTicket"

      this.$store.state.alert=true
      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: functionToCall,
            args:[
              { type:"string", value: this.$route.params.eventId },
              { type:"string", value: this.name },
              { type:"string", value: this.description },
              { type:"integer", value: this.price * Math.pow(10, 8) },
              { type:"integer", value: this.maxAvailable },
              { type:"integer", value: this.maxUse },
              { type:"integer", value: this.royalties },
              { type:"integer", value: !this.nolimit ? this.resellLimitation.getTime() : 0 }
            ]
        }
      }
      if(this.ticketToEdit && this.ticketToEdit.ticket_name){
        dataTx.call.args.unshift({ type:"string", value: this.ticketToEdit.txid })
      }
      /* dataTx.payment = [{ assetId: _this.$store.state.config.assetID, amount: (_this.$store.state.certificationPrice * Math.pow(10,8)).toFixed(0) }]  */

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.resetWindow()
            this.responseMessage = 'Ticket successfully added!'
            setTimeout(function(){
              _this.responseMessage = ''
            }, 3000)
          }
      })

    }
  },
  watch: {
    show: function(show){
      if(show){
        console.log("chaud!")
        if(this.ticketToEdit && this.ticketToEdit.ticket_name){
          this.name = this.ticketToEdit.ticket_name
          this.description = this.ticketToEdit.ticket_desc
          this.maxAvailable = this.ticketToEdit.ticket_maxAvailable
          this.price = this.ticketToEdit.ticket_price / Math.pow(10,8)
          this.maxUse = this.ticketToEdit.ticket_maxUse
          this.royalties = this.ticketToEdit.ticket_royalties
          this.resellLimitation = this.ticketToEdit.ticket_resellLimitation != 0 ? new Date(this.ticketToEdit.ticket_resellLimitation) : new Date()
          this.nolimit = this.ticketToEdit.ticket_resellLimitation != 0 ? false : true

        }else{
          this.name = ""
          this.description = ""
          this.maxAvailable = 0
          this.price = 0
          this.maxUse = 1
          this.royalties = 5
          this.resellLimitation = this.lowerLimit()
          this.nolimit=true
        }
        document.querySelector("body").style.overflowY = "hidden"
      }else{
        Object.assign(this.$data, initialState);
        document.querySelector("body").style.overflowY = "auto"
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.popin{
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 38, 58, 0.9);
  color: white;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  left: 0;
  overflow: auto;
  padding: 30px 0;
  #createEvent{
    width: 100%;
    max-width: 650px;
    padding: 20px;
  }
  .fa-xmark{
    font-size: 26px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor:pointer;
  }
  label{
    margin-top: 5px;
  }
}

</style>
