<template>
  <div class="col-lg-3 flyer">
    <router-link :to="'/admin/event/'+event.txid">
      <imageLoader 
        :source="event.event_displayCid ? getIpfsLink(event.event_displayCid) : emptySrc" 
        :imgix="$parent.imgix && $parent.imgix[keyIndex].url ? $parent.imgix[keyIndex].url : event.event_displayCid" 
        :altname="event.event_name" 
      />  
    </router-link>
  </div>
  <div class="col-lg-9 content">
    <div v-if="event.event_canceled">CANCELED <p>{{event.event_canceledReason}}</p></div>
    <h2>{{event.event_name}}</h2>
    <div class="date">Event date: {{ event.event_date }}</div>
    <div class="desc">{{ trim(event.event_desc, 150)}}</div>
    
    <div class="tickets_details">
      <Creator :user_addr="event.user_addr" />
      <div class="me-2 ms-2">-</div>
      <div class="tickets_sold">Sold: <span>{{event.event_totalIssued}}</span></div>
      <div class="tickets_price">Starting price: <span>{{setStartingPrice}} SIGN</span></div>
      
    </div>

    

    <div :class="{error: haveError}" v-show="responseMessage!=''" class="response col-xl-10 mx-auto text-center mt-3 mb-3">
      <div class="wrap">{{responseMessage}}</div>
    </div>

    <div class="actions mt-4">
      <button class="btn btn-primary mb-3 mb-lg-0 me-3" @click="editParentEvent()">EDIT</button>
      <router-link :to="`/admin/event/${event.txid}`" class="btn btn-primary mb-3 mb-lg-0 me-3">MANAGE TICKETS</router-link>
      <router-link class="btn btn-primary mb-3 mb-lg-0 me-3" :to="`/checkin/${event.txid}`" > START CHECK IN</router-link>
      <button class="btn btn-danger mb-3 mb-lg-0 me-3" v-if="event.event_totalIssued == 0" @click="deleteEvent">DELETE</button>
      <button class="btn btn-danger mb-3 mb-lg-0" v-else @click="showCancelPopin = !showCancelPopin">CANCEL</button>
    </div>

    <div class="cancelPopin d-flex mt-3 w-100" v-if="showCancelPopin">
      <input type="text" placeholder="Enter the canceling reason" class="form-control me-2 " v-model="cancelReason" /> <button @click="cancelEvent" class="btn btn-danger" >CONFIRM</button>
    </div>
    
  </div>
</template>

<script>
import encodeUrl from 'encodeurl';
import imageLoader from '@/components/elements/ImageItem.vue' 
import Global from '@/mixins/global.js';
import Creator from '@/components/admin/elements/EventItemCreator.vue' 
export default {
  name: 'Manage-page-item',
  mixins: [Global],
  props:['event', 'keyIndex'],
  emits: ["editEvent"],
  data: function(){
    return {
      haveError:false,
      responseMessage:'',
      cancelReason: '',
      showCancelPopin: false,
      startingPrice: 0,
    }
  },
  components:{
    Creator,
    imageLoader
  },
  created(){
    this.getStartingPrice(this.event.txid)
  },
  computed: {
    setStartingPrice(){
      return this.numberWithSpaces(this.startingPrice / Math.pow(10,8))
    }
  },
  methods: {
    getTicketsLowerPrice: async function(obj){
      let res =  await Math.min(...obj.map(item => item.value));
      return res == Infinity ? 'N/A' : res
    },
    getStartingPrice: async function(eventId){
      let regex = encodeUrl("^ticket_price_"+eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json() 
      this.startingPrice = await this.getTicketsLowerPrice(getList)
    },
    editParentEvent: function(){
      console.log("hihihi")
      this.$emit("editEvent", this.event);
    },
    deleteEvent: async function(){
      console.log("processing delete...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: "deleteEvent",
            args:[
              { type:"string", value: this.event.txid }
            ]
        }
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Event successfully deleted!'
            setTimeout(function(){
              _this.responseMessage = ''
              // parent reload list
            }, 3000)
          }
      })
    },
    cancelEvent: async function(){
      console.log("processing delete...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: "cancelEvent",
            args:[
            { type:"string", value: this.event.txid },
            { type:"string", value: this.cancelReason }
            ]
        }
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Event successfully canceled!'
            setTimeout(function(){
              _this.responseMessage = ''
              // parent reload list
            }, 3000)
          }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.addeddate{
  font-size: 13px;
  color: #999;
}
</style>
