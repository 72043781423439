<template>
  <div class="page">
    <h3 class="mt-4 mb-4">Profile front</h3>
  </div>
</template>

<script>
export default {
  name: 'profile-front-page'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
