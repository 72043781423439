<template>
    <div class="loadingText" v-if="!isLoaded">
      Loading organizer detail...
    </div>
    <div class="organizer" v-else>
      <router-link :to="`profile/${user_addr}`">
        <span v-if="userData">
          <img :src="getIpfsLink(userData.user_thumb)" /> By <span>{{ userData.user_name }}</span>
        </span>
        <span v-else>
          <img :src="emptyThumb" /> By <span>{{ shortTxAddr(user_addr) }}</span>
        </span>
      </router-link>
    </div>
</template>

<script>
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
export default {
  name: 'Manage-page-item-owner',
  mixins: [Global],
  props:['user_addr'],
  data: function(){
    return {
      userData: {},
      isLoaded: false
    }
  },
  created(){
    this.getArtistData(this.user_addr)
  },  
  methods:{
    getArtistData: async function(address){
      this.isLoaded = false
      let regex = encodeUrl("^user_(.*)_"+address)
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_users}?matches=${regex}`)
      getList = await getList.json()
      let orderedList = this.reduceData(getList)
      this.userData = orderedList[address]
      console.log("this.userData this.userData this.userData this.userData")
      console.log(this.userData)
      this.isLoaded = true
    },
    reduceData: function(data){
      let newData = Object.values(data).reduce((acc, curr) => {
        const _key = curr.key;
        const getAddr = _key.substr(_key.lastIndexOf("_") + 1, _key.length);
        const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
        if (!acc[getAddr]) {
          acc[getAddr] = [];
          acc[getAddr]["user_addr"] = getAddr;
        }
        acc[getAddr]["" + getKeyWithoutAddr] = curr.value;
        return acc;
      }, {});
      return newData
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
