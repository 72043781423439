<template>
  <div id="admin-profile">
    <div class="container">
      <div class="row">
        <div class="bg"></div>
        <div class="content_wrapper">
          <div class="container mb-3">
            <div class="row">
              <div class="col-lg-8 d-flex offset-lg-2 wrap_profile_intro justify-content-between">
                <div class="wrap_text">
                  <h2 class="mb-0 pb-0 text-uppercase">Update your profile</h2>
                  <div class="mb-4 mt-1">
                    <p class="mb-1" v-if="note == ''">Account with status: <strong>{{ status != '' ? status : 'UNREGISTERED' }}</strong></p>
                    <p v-else id="suspended">{{ status }} - {{note}}</p>
                  </div>
                </div>
                <div class="wrap_preview">
                  <img :src="previewImage" class="uploading-image d-block mx-auto" :class="{'fade': imageCompressionAlert!='' && imageCompressionAlert!='Image too big'}"/>
                  <div class="imageCompressionAlert mention" v-if="imageCompressionAlert!=''">{{imageCompressionAlert}}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="response" :class="{error: haveError}" v-show="responseMessage!=''" class="col-xl-10 mx-auto text-center mt-3">
            <div class="wrap">{{responseMessage}}</div>
          </div>
          <form>
            <div class="form-group">
              
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-8 offset-lg-2">
                    <div class="form-group">
                      <label for="file">Choose a picture for your profile <span class="mention">(80Kb maximum or it will be resized)</span></label>
                      <input type="file" id="thumbnail" @change="uploadImage($event)" class="form-control mb-2" accept="image/*"/>
                    </div>
                    <div class="form-group">
                      <label for="userName">Choose a public name <span class="mandatory">*</span> ({{ userName.length }} / 45 characters)</label>
                      <input type="text" id="userName" v-model="userName" class="form-control mb-2" placeholder="Displayed name" maxlength="45"/>
                    </div>
                    <div class="form-group">
                      <label for="userIntro">Enter your description <span class="mandatory">*</span> ({{ userIntro.length }} / 600 characters)</label>
                      <textarea id="userIntro" v-model="userIntro" class="form-control mb-2" placeholder="Description"></textarea>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-6 mb-2">
                        <label for="facebook">Facebook link</label>
                        <input type="text" id="facebook" v-model="social.facebook" class="form-control mb-2" placeholder="ex: https://www.facebook.com/julienVersan/"/>
                      </div>
                      <div class="form-group col-lg-6 mb-2">
                        <label for="twitter">Twitter link</label>
                        <input type="text" id="twitter" v-model="social.twitter" class="form-control mb-2" placeholder="ex: https://twitter.com/wavesplatform"/>
                      </div>
                      <div class="form-group col-lg-6 mb-2">
                        <label for="instagram">Instagram link</label>
                        <input type="text" id="instagram" v-model="social.instagram" class="form-control mb-2" placeholder="ex: https://www.instagram.com/joeygatesart"/>
                      </div>
                      <div class="form-group col-lg-6 mb-2">
                        <label for="website">Website link</label>
                        <input type="text" id="website" v-model="social.website" class="form-control mb-2" placeholder="ex: https://www.signature-chain.com/"/>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-8 offset-lg-2 text-center mt-4 mb-4">
                    <p class="mandatory">* Mandatory fields</p>
                    <button class="btn btn-primary mb-2 bigger" @click.prevent="updateInfos">UPDATE INFORMATION</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import Global from '@/mixins/global.js';
/* import Register from '@/components/elements/Register.vue' */
import Jimp from 'jimp';
import encodeUrl from 'encodeurl';

export default {
  name: "admin-profile",
  mixins: [Global],
  data: function(){
    return {
      previewImage:null,
      imageCompressionAlert: "",
      ipfsThumbCid: this.$store.state.user.thumbnail,
      addr: this.$store.state.user.userAddress,
      dappUsers: this.$store.state.config.dapp_addr_users,
      userName: '',
      userIntro: '',
      status: '',
      note: '',
      userErc20: '',
      social: {
        facebook:'',
        twitter: '',
        instagram: '',
        website: ''
      },
      haveError: false,
      responseMessage:'',
      statusNotAllowed: this.$store.state.user.status != 'ALLOWED'
    }
  },
/*   components:{
    "register": Register
  }, */
  methods: {
    loadImage: async function(path) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = path
        img.onload = () => {
          resolve(img)
        }
        img.onerror = e => {
          reject(e)
        }
      })
    },
    compressImage: async function (image){
      let tempImg, compression = 100, tempImgB64 = []
          while(tempImgB64.length > 80000 || tempImgB64.length == 0){
            try{
              tempImg = await Jimp.read(image)
              await tempImg.cover(300, 300).quality(compression);
              tempImgB64 = await tempImg.getBase64Async(Jimp.MIME_JPEG)
            }catch(err){
              console.log(err)
            }
            compression -= 10
            console.log(tempImgB64.length)
          }
          return tempImgB64
    },
    uploadImage: async function(e){
      if(!this.isProcessingImage){
        this.isProcessingImage = true
        if(e.target.files[0].size > 2000000){this.imageCompressionAlert = 'Image too big'; this.isProcessingImage = false; return}
        this.imageCompressionAlert = ''
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = async e => {
          await this.loadImage(reader.result)
          if(image.size < 85000){
            this.previewImage = e.target.result;
          }else{
            this.imageCompressionAlert = "Image over 80 KB, processing compression, please wait..."
            this.previewImage = await this.compressImage(reader.result)
            this.imageCompressionAlert = ""
          }
        }
        this.isProcessingImage = false
      }
    },
    progress: function(data){
      let completedPercentage = parseInt( ( data.loaded / data.total ) * 100 );
      if(document.getElementById("msg-layer").querySelector(".progress-txt")){
        if(!data.addingToIpfs){
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = completedPercentage+"%"
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = completedPercentage+"%"
        }else{
          document.getElementById("msg-layer").querySelector(".progress-txt").innerHTML = "Adding file to IPFS, please wait..."
          document.getElementById("msg-layer").querySelector(".progress-bar").style.width = "100%"
        }
      }
    },

    urlToFile: async function(url, filename) {
      const res = await fetch(url);
      const buf = await res.arrayBuffer();
      return new File([buf], filename);
    },
    updateInfos: async function(){
      let _this = this
      this.haveError = false
      this.responseMessage = 'Checking data...'
      
      let fileInput = document.getElementById("thumbnail")
      let file = this.previewImage
      let thumbnail
      
      if(this.userName == ''){
        this.haveError = true
        this.responseMessage = 'Name cannot be empty'
        return
      }

      if(this.userIntro == ''){
        this.haveError = true
        this.responseMessage = 'Description cannot be empty'
        return
      }

      if(this.social.facebook != '' && this.social.facebook.indexOf("facebook.com") == -1){
        this.haveError = true
        this.responseMessage = 'Wrong link for facebook field'
        return
      }
      if(this.social.twitter != '' && this.social.twitter.indexOf("twitter.com") == -1){
         this.haveError = true
        this.responseMessage = 'Wrong link for twitter field'
        return
      }
      if(this.social.instagram != '' && this.social.instagram.indexOf("instagram.com") == -1){
        this.haveError = true
        this.responseMessage = 'Wrong link for instagram field'
        return
      }

      this.$store.state.alert=true
      this.$store.state.alertMessage=this.responseMessage
      console.log("filefilefilefilefile")
      console.log(file)
        if(fileInput.files[0]){
          if(file.length > 85000){
            this.responseMessage = "Image is too big, 80Kb maximum"
            this.haveError = true
            this.$store.state.alert=false
            this.$store.state.alertMessage=""
            return
          }
          this.$store.state.alertMessage='Sending your files to IPFS, Please wait...'      
          let ext = this.getFileExtension(fileInput.files[0].name)
          console.log("ext")
          console.log(ext)
          let new_thumbnail = await this.urlToFile(file,'thumbnail.'+ext)
          console.log("new_thumbnail")
          console.log(new_thumbnail)
          let addThumbToIpfs = await this.$store.state.ipfs.upload(new_thumbnail, this.progress)
          console.log("addThumbToIpfs")
          console.log(addThumbToIpfs)
          thumbnail = addThumbToIpfs.cid.string+"/"+new_thumbnail.name
      }else{
        thumbnail = this.ipfsThumbCid
      }

      let functionToCall = 'registerUser'
     
      if(this.$store.state.user.isRegistered){
        functionToCall = 'updateUser'
      }
      
      
      /* let invokeFee = this.$store.state.sponsoredFee * 5 * 10 ** 8; */
      const dataTx = {
        dApp: this.dappUsers,
        /* feeAssetId: this.$store.state.config.assetID,  */
            call: {
                function: functionToCall,
                 args:[
                    { type:"string", value: this.userName },
                    { type:"string", value: this.userIntro },
                    { type:"string", value: thumbnail },
                    { type: "string", value: JSON.stringify(this.social)}
                ]
            }
      }

      /* if(this.dappWrite == this.$store.state.config.dapp_addr_collectors && !this.$store.state.user.isRegisteredAsCollector){
       dataTx.payment = [{ assetId: this.$store.state.config.assetID, amount: ((this.$store.state.certificationPrice * Math.pow(10,8))*2).toFixed(0) }] 
      } */

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'
      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            console.log(res.error)
            this.responseMessage = res.error.message
            this.haveError = true
          }else{
            // display confirmation message of success
            this.responseMessage = 'Informations updated!'
            this.haveError = false
            this.$store.state.user.thumbnail = thumbnail
            if(this.$store.state.user.isWhiteListed){
              this.$store.state.user.isRegistered = true
              if(this.$store.state.user.status == "ALLOWED"){
                this.$store.state.user.status == 'REGISTERED'
                this.$store.dispatch("updateStatus")
              }
            }else{
              this.$store.state.user.isRegisteredAsCollector = true
            }
            _this.init()
          }
      })
      

    },
    init: async function(){
      console.log("init() called")
    document.querySelector(".wrap").classList.add("show")
    const _this = this
    let regex = encodeUrl("^user(.*)" + _this.addr)
    fetch(this.$store.state.config.nodeURL+"/addresses/data/"+this.dappUsers+"?matches="+regex).then(res => res.json()).then(data => {
      console.log("data")
      console.log(data)
          let newData = Object.values(data).reduce((acc, curr) => {
            const _key = curr.key;
            const getAddr = _key.substr(_key.lastIndexOf("_") + 1, _key.length);
            const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
            if (!acc[getAddr]) {
              acc[getAddr] = [];
              acc[getAddr]["user_addr"] = getAddr;
            }
            acc[getAddr]["" + getKeyWithoutAddr] = curr.value;
            return acc;
          }, {});

          let orderedData = Object.values(newData).sort(function(a, b) {
            return new Date(b.user_date) - new Date(a.user_date);
          });

          if(orderedData[0]["user_name"]){
            if(orderedData[0]["user_status"] == "SUSPENDED"){
              this.$store.state.user.isSuspended=true
            }
            _this.userName = orderedData[0].user_name
            _this.userIntro = orderedData[0].user_desc
            _this.ipfsThumbCid = orderedData[0].user_thumb
            _this.$store.state.user.thumbnail = orderedData[0].user_thumb
            _this.previewImage = _this.getIpfsLink(orderedData[0].user_thumb)
            try {
            let social = JSON.parse(orderedData[0].user_social)
              _this.social.facebook = social.facebook
              _this.social.twitter = social.twitter
              _this.social.instagram = social.instagram
              _this.social.website = social.website
            }catch(err){
              console.log("Invalid json")
            }
            _this.note = orderedData[0].user_note ? orderedData[0].user_note : ''

            _this.status = orderedData[0]["user_status"]
            
          }
        }).catch((err) => console.error(err));
    },
  },
  watch:{
    '$store.state.user.status': function(status){
      console.log("status changed")
      this.statusNotAllowed = status != 'ALLOWED'
      this.status = status
    },
    '$store.state.user.userAddress': function(addr){
      console.log("address changed: "+addr)
      this.addr=this.$store.state.user.userAddress
      this.previewImage = this.emptyThumb
      this.imageCompressionAlert= ""
      this.ipfsThumbCid= this.$store.state.user.thumbnail
      this.addr= this.$store.state.user.userAddress
      this.dappUsers= this.$store.state.config.dapp_addr_users
      this.userName= ''
      this.userIntro= ''
      this.status= ''
      this.note= ''
      this.userErc20= ''
      this.social= {
        facebook:'',
        twitter: '',
        instagram: '',
        website: ''
      }
      if(addr!=""){
        this.init()
      }
    }
  },
  mounted(){
    if(this.$store.state.user.userAddress!=""){
      this.init()
    }
  }
}
</script>
<style lang="scss" scoped>

  .imageCompressionAlert{
    text-align: center;
    margin-bottom: 25px;
    margin-top: 10px;
    color: #fff;
    background-color: #9a4ac5;
    padding: 5px;
    border-radius: 5px;
  }
  textarea.form-control {
    height: 200px !important;
  }
  .uploading-image{
    max-width: 80px;
    margin-bottom: 20px;
    border-radius: 50%;
    &.fade{
      opacity: 0.5;
    }
  }
  .migrate{
    padding: 10px;
    background-color: #9a4ac5;
    color: white;
    border-radius: 5px;
  }
  .fee_collector_account{
    background-color: #9a4ac5;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  .bg{
    background: url("@/assets/images/bg-blue.jpg");
  }

</style>