<template>
  <transition name="fade" >
    <div id="bidPopin" ref="bidPopin" >
      <div class="wrapper">
        <div class="form-group mb-0">
          <div class="row">
            <div class="col-lg-12">
              
              <div v-if="secondary.sell_price == 0"><h4 class="text-center mb-2 dottedBottom"><span>OFFER ONLY</span></h4></div>
              <div v-else><h4 class="text-center mb-2 dottedBottom">Current listing price:<br/><span>{{ numberWithSpaces(getCurrentNFTPrice()) }} <app-UsdPrice :secondary="secondary" class="bleu"/></span></h4></div>
              
              <p v-if="currentHighestBid" class="text-center dottedBottom"><strong class="d-block">Current highest OFFER:</strong> {{ numberWithSpaces(currentHighestBid.price) }} {{ currentHighestBid.assetId }} (${{ numberWithSpaces(currentHighestBid.priceInUsd.toFixed(2)) }})</p>
              <p v-if="haveBid" class="haveBid mb-3"><strong>You already have a pending offer</strong>, new offers will be added but won't replace your existing one.</p>

            </div>
            <div class="col-lg-12 mt-1">
              <label for="price">Enter the offered price in SIGN <span class="mandatory">*</span> <span title="Enter the amount you offer."><i class="fas fa-question-circle"></i></span></label>
              <input type="number" class="form-control" id="price" placeholder="Price per NFT" v-model="bid.price" :min="0">
              <div class="smallBalance mt-1" v-show="offerInUsdn!=0">Equivalent to ${{ offerInUsdn.toFixed(2) }}</div>
            </div>
            <div class="col-12">
              <div :class="{error: bid.haveError}" v-show="bid.errorMessage!=''" class="response container w-100 mx-auto text-center mt-3">
                <div class="wrap">{{bid.errorMessage}}</div>
              </div>
              <button class="btn btn-primary bigger mb-0 mt-3 mx-auto d-block bleu" @click.prevent="processBid">{{ bid.price != 0 && bid.edit ? 'EDIT OFFER' : 'MAKE AN OFFER' }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';
import UsdPrice from '@/components/elements/UsdPrice.vue' 
import encodeUrl from 'encodeurl';

export default {
  mixins: [globalMixin],
  props: ["event", "ticket", "secondary", "showBid", "currentHighestBid", "hideBid", "updateOfferList"],
  data: function(){
    return {
      bid: {
        popinOpen: this.showBid,
        price: 0,
        edit: false,
        assetId: this.$store.state.config.assetID,
        ticker: "SIGN",
        haveError: false,
        errorMessage: ""
      },
      feeAssetId: this.$store.state.config.assetID,
      finalPrice: 0,
      responseMessage: '',
      haveBid: false,
      offerInUsdn: 0
    }
  },
  components: {
    'app-UsdPrice': UsdPrice
  },
  watch: {
    'bid.price'(){
      this.updateOfferToUsd()
    },
    'bid.assetId'(){
      this.updateOfferToUsd()
    },
  },
  created(){
    this.init()
  },
  mounted(){
    document.getElementById('bidPopin').addEventListener('mousedown', this.detectClickOutOfBidPopin);
  },
  beforeUnmount() {
    if(document.getElementById('bidPopin')){
      document.getElementById('bidPopin').removeEventListener('mousedown', this.detectClickOutOfBidPopin);
    }
  },
  methods: {
    init: function(){
      console.log("this.event")
      console.log(this.event)

      console.log("this.ticket")
      console.log(this.ticket)

      console.log("this.secondary")
      console.log(this.secondary)
      
      this.calculatePriceAndTxFee()
      this.userAlreadyHaveBid()
    },
    updateOfferToUsd: function(){
      this.offerInUsdn = this.bid.price * this.$store.state.priceInUSDN.signPriceInUsd
    },
    userAlreadyHaveBid: function(){
      const _this = this
      let regex = encodeUrl("^offer2nd_"+ _this.$store.state.user.userAddress + "_"+ _this.secondary.nftId+"_(.*)_OPEN")
      fetch(this.$store.state.config.nodeURL+"/addresses/data/"+_this.$store.state.config.dapp_addr_secondary+"?matches="+regex).then(res => res.json()).then(data => {
        _this.haveBid = data.length > 0
      })
    },
    getCurrentNFTPrice: function(){ // is bid higher than current asked price?
      let decimal = 8
      let currentArtPrice = this.secondary.sell_price / Math.pow(10, decimal)
      return currentArtPrice + " SIGN"
    },
    setTicker: function(){
      this.bid.ticker = "SIGN"
      this.calculatePriceAndTxFee()
    },

    calculatePriceAndTxFee: function(){
      this.finalPrice = this.bid.price * 10 ** 8
    },
    processBid: function(){
        let _this = this;
        this.$store.state.alert=true
        this.$store.state.alertMessage='Processing the offer, Please wait...'

        this.calculatePriceAndTxFee()

        const dataTx = {
          dApp: this.$store.state.config.dapp_addr_secondary,
            call: {
                function: "setOffer",
                args:[
                    { type:"string", value: _this.secondary.nftId }
                ]
            }
        }

        dataTx.payment = [{ assetId: _this.bid.assetId, amount: this.finalPrice }]


        this.$store.dispatch("signerInvoke", dataTx).then(res => { 
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            
            console.log("res in set bid window")
            console.log(res)
            if(res.error){
              // siplay error
              this.bid.errorMessage = res.error.message ? res.error.message : res.error
              this.bid.haveError = true
            }else{
              // display confirmation message of success
              _this.$store.state.alert=false
              _this.$store.state.alertMessage=''

              this.$emit("updateOfferList") 
              this.$emit("hideBid", {haveError: false, responseMessage: 'Offer set successfully!'})
            }
        }) 
      
    },
    detectClickOutOfBidPopin: function(e){   

      if(this.showBid){
        if (!document.getElementById('bidPopin').querySelector(".wrapper").contains(e.target)){
          this.bid.errorMessage = ''
          this.bid.haveError = false
          this.$emit("hideBid", {haveError: false, responseMessage: ''})
          //document.getElementById("content").style.overflowY="auto"
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
label{
  color: #000;
  text-align: center;
  width: 100%;
}
.smallBalance{
  font-size: 14px;
  color: #979797;
}
  .usdPrice.bleu, .mandatory{
    color: #151717!important;
  }
  .dottedBottom{
    border-bottom: dotted 1px black;
    padding-bottom: 10px;
  }
  .haveBid{
    color: #57137b;
    text-align: center;
  }
  h4{
    font-weight: 600;
    font-size: 20px;
    span{
      color: #151717;
      font-size: 24px;
    }
  }
  .btn.bleu{
    background-color: #151717;
    border-radius: 50px;
    &:hover{
      background-color: #1E1A1A;
    }
  }
</style>
<style lang="scss">
.response .wrap{
  overflow: hidden;
}
.alertBid{
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
</style>