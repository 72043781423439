<template>
  <div class="container">
    <div class="bg"></div>
    <div class="content_wrapper">
      <h3 class="mt-4 mb-4">Confirm use of this ticket: <span class="d-block">With address: {{shortTxAddr(owner)}}</span></h3>
      <div class="events">
        <div class="event">
          <Ticket 
            v-if="nftInfo"
            :nftInfo="nftInfo" 
            :index="index"
            :updateCount="updateCount"
          ></Ticket>
        </div>
      </div>
      <div :class="{error: haveError}" v-show="responseMessage!=''" class="response col-xl-10 mx-auto text-center mt-3 mb-3">
        <div class="wrap">{{responseMessage}}</div>
      </div>
      <div class="warning_notice">
        <label for="acceptTerms">
          <input type="checkbox" id="acceptTerms" name="accept" v-model="acceptTerms"/> 
          I confirm that i’m sure that the request is legit and coming from the event organizer. I understand that once the ticket is used, it cannot be used again unless it is a multi-usage ticket but in that case, 1 use will still be counted. 
        </label>
      </div>
      <button class="d-block btn btn-primary big mt-2 mx-auto" @click.prevent="confirm()" :disabled="!acceptTerms">CONFIRM</button>
    </div>
  </div>

</template>

<script>
import Global from '@/mixins/global.js';
import Ticket from '@/components/admin/elements/ownedNFTdetail.vue' 
let initialState = {
      haveError: false,
      responseMessage: '',
      nftInfo: null,
      updateCount: 0,
      acceptTerms: false,
      owner: ''
    }

export default {
  name: 'Event-page-confirmation',
  mixins: [Global],
  data: function(){
    return initialState
  },
  components: {
    Ticket
  },  
  async created(){
    let data = await fetch(this.$store.state.config.nodeURL+"/assets/details/"+this.$route.params.nftId)
    data = await data.json()
    console.log(data)
    this.nftInfo = data

    let currentHeight = await this.getCurrentHeight()   
    console.log("currentHeight")
    console.log(currentHeight) 
    let owner = await fetch(this.$store.state.config.nodeURL+"/assets/"+this.$route.params.nftId+"/distribution/"+(currentHeight-1)+"/limit/1")
    owner = await owner.json()
    console.log("owner")
    console.log(owner)
    this.owner = owner.lastItem
  },
  methods:{
    confirm: async function(){
      console.log("processing buy...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: "useTicket",
            args:[]
        },
        payment: [
          { 
            assetId: this.$route.params.nftId, 
            amount: 1 
          }
        ]
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Ticket successfully used!'
            this.updateCount++
            setTimeout(function(){
              _this.responseMessage = ''
              // parent reload ticket list
            }, 3000)
          }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
h3{
  text-transform: uppercase;
}
.bg{
  background: url("@/assets/images/bg-blue.jpg");
  }
  h3{
    span{
      font-size: 20px;
      font-weight: 400;
      text-transform: lowercase;
    }
  }
  @media(max-width: 600px){
    h3{
      font-size: 20px;
    span{
      font-size: 16px;
      font-weight: 300;
    }
  }
  }
</style>