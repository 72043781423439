import { createStore } from "vuex";
import { Signer } from "@waves/signer";
import encodeUrl from 'encodeurl';
import { ProviderWeb } from "@waves.exchange/provider-web";
import { ProviderCloud } from '@waves.exchange/provider-cloud';
import { ProviderLedger } from '@waves/provider-ledger';
import { ProviderKeeper } from '@waves/provider-keeper';
import { ProviderKeeperMobile } from '@keeper-wallet/provider-keeper-mobile';
import { ProviderMetamask } from '@waves/provider-metamask';
//import { isProxy, toRaw } from 'vue' // needed to destructure the vue3 proxied objects 
import { nodeInteraction, libs, validators } from "@waves/waves-transactions";
import ipfs from "../plugins/ipfs.js";
import Web3 from 'web3';

import { ethTxId2waves } from '@waves/node-api-js';

let configObj = {
  baseURL: process.env.VUE_APP_baseURL,
  network: process.env.VUE_APP_network,
  wavesExplorer: process.env.VUE_APP_wavesEplorer,
  assetID: process.env.VUE_APP_assetID,
  dapp_addr_users: process.env.VUE_APP_dapp_addr_users,
  dapp_addr_events: process.env.VUE_APP_dapp_addr_events,
  dapp_addr_secondary: process.env.VUE_APP_dapp_addr_secondary,
  signOracle: process.env.VUE_APP_signOracle,
  ipfsGateway: process.env.VUE_APP_ipfsGateway,
  nodeURL: process.env.VUE_APP_nodeURL,
  providerUrl: process.env.VUE_APP_providerUrl,
  signArtApiURL: process.env.VUE_APP_signArtApiURL
}

let SignerInstance = null
let ProviderInstance = null

export const store = createStore({
  state: {
    config: configObj,
    nodeInteraction: nodeInteraction,
    cryptolibs: libs.crypto,
    ipfs: ipfs,
    alert:false,
    alertMessage: '',
    loginRedirect: false,
    signingChoice: {
      error: null,
      show: false,
      keeper: false,
      signer: false
    },
    signer: {
      isMetamask: false,
      metamaskLogged: false
    },
    user: {
      userAddress: "",
      publicKey: "",
      thumbnail: "",
      isLogged: false,
      signBalance: null,
      isSuspended: false,
      isRegistered: false
    },
    acceptedFiles: {
      display: {
        accept: ["image/png", "image/gif", "image/jpg", "image/jpeg"/* , "video/mp4" */],
        extVid: [/* "mp4" */],
        extImg: ["jpg", "jpeg", "gif", "png"]
      },
      crawler: {
        accept: ["image/png", "image/jpg", "image/jpeg"],
        extImg: ["jpg", "jpeg", "png"]
      }
    },
    priceInUSDN: {
      signPriceInUsd: 0,
      usdnPrice: 0
    }
  },
  getters: {
    getSignerInstance: function(){
      return Signer.signer
    }
  },
  mutations: {
    initSigner: function(state, loginType) {
      let node = state.config.nodeURL
      SignerInstance = new Signer({
        NODE_URL: node,
      });

      if(loginType == "signer"){
        ProviderInstance = new ProviderWeb(
          state.config.providerUrl
        ); 
        SignerInstance.setProvider(ProviderInstance);

      }
      if(loginType == "signer-email"){
        SignerInstance.setProvider(new ProviderCloud());
      }
      if(loginType == "signer-keeper"){
        SignerInstance.setProvider(new ProviderKeeper());
      }
      if(loginType == "signer-keeper-mobile"){
        SignerInstance.setProvider(new ProviderKeeperMobile());
      }
      if(loginType == "ledger"){
        let code = state.config.network == "mainnet" ? 87 : 84
        SignerInstance.setProvider(new ProviderLedger({
          wavesLedgerConfig: { networkCode: code }
        }));
      }

      if(loginType == "metamask"){
        state.signer.isMetamask = true
        let code = state.config.network == "mainnet" ? 87 : 84
        SignerInstance.setProvider(new ProviderMetamask({
          wavesConfig: {
            nodeUrl: state.config.nodeURL,
            chainId: code
          }
        }));
      }else{
        state.signer.isMetamask = false
      }
      return
      
    },
    getUserInfos: function(state) {
      if (state.user.userAddress) {
        state.user.checkingInfos = true;
        state.nodeInteraction.accountData({ address: state.config.dapp_addr_users, match: "^user(.*)" + state.user.userAddress }, this.state.config.nodeURL)
          .then((res) => {
            let status = res["user_status_" + state.user.userAddress];
            let thumb = res["user_thumb_" + state.user.userAddress];
            if (status) {
              if (status.value != "SUSPENDED") {
                state.user.isSuspended = false;
              } else {
                state.user.isSuspended = true;
              }
              state.user.status = status.value
              state.user.isRegistered = true

              console.log("state.user.status")
              console.log(state.user.status)

              console.log("state.user.isRegistered")
              console.log(state.user.isRegistered)
            }
            if (thumb) {
              state.user.thumbnail = thumb.value;
            }
            state.user.checkingInfos = false;
            if(state.loginRedirect){
              state.user.userReady = true
            }
            state.loginRedirect = false
          })
          .catch((err) => {
            console.log(err);
            state.user.checkingInfos = false;
          });
      }
    },
    setPriceInUsd: function(state, res){
      state.priceInUSDN = res
    },
    logout: async function(state){
      state.user = {
        userAddress: "",
        publicKey: "",
        thumbnail: "",
        isLogged: false,
        signBalance: null,
        isSuspended: false,
        isRegistered: false
      }
    }
  },
  actions: {
    logoutUser: function(){
      this.commit("logout");
    },
    getPriceInUsd: async function(){

      let _this = this
      let signPriceFromSwop, wavesPriceFromSwop

      if(this.state.config.network == "testnet"){
        signPriceFromSwop = 0.00580688272679572
        wavesPriceFromSwop = 28.23244825165835

      }else{
        let regex = encodeUrl("^(.*)_asset_balance")
        signPriceFromSwop = await fetch(_this.state.config.nodeURL + "/addresses/data/3P4Ftyud3U3xnuR8sTc1RvV4iQD62TcKndy?matches="+regex)
        signPriceFromSwop = await signPriceFromSwop.json()
        signPriceFromSwop = ( signPriceFromSwop[1].value   / Math.pow(10,6)) / ( signPriceFromSwop[0].value  / Math.pow(10,8))

        wavesPriceFromSwop = await fetch(_this.state.config.nodeURL + "/addresses/data/3PHaNgomBkrvEL2QnuJarQVJa71wjw9qiqG?matches="+regex)
        wavesPriceFromSwop = await wavesPriceFromSwop.json()
        wavesPriceFromSwop = ( wavesPriceFromSwop[1].value   / Math.pow(10,6)) / ( wavesPriceFromSwop[0].value  / Math.pow(10,8))
      }

      _this.commit("setPriceInUsd", {signPriceInUsd: signPriceFromSwop, usdnPrice: wavesPriceFromSwop});

      return

    },
    loginMetamask: async function(){
      if(!this.state.signer.metamaskLogged && this.state.signer.isMetamask){
        let web3 = new Web3(window.ethereum || "ws://localhost:8545");
        let _this = this
        web3.eth.getAccounts(function(err, accounts){
          if (err != null) {
            console.error("An error occurred: "+err);
          }else if (accounts.length == 0) {
            console.log("User is not logged in to MetaMask");
          }else{
            console.log("User is logged in to MetaMask");
            _this.state.signer.metamaskLogged = true
          }
        });
        
        try{
          await SignerInstance.login()
        }catch(err){
          console.log(err)
        }
      }
    },
    signerLogin: async function(context, choice) {
      this.state.signingChoice.error = null
      let data
        try{
          await this.commit("initSigner", choice)
          data = await SignerInstance.login()
        }catch(error){
          console.log(error)
          let betterError = error.toString().includes("not equals keeper connect") ? "Please switch keeper to "+ this.state.config.network : error
          data = { error: betterError, result: false }
        }
      //}
      if(!data.error){

        this.state.signingChoice.show = false
        this.state.signingChoice.signer = choice == "signer" || choice == "signer-email" || choice == "ledger" || choice == "signer-keeper" || choice == "signer-keeper-mobile" || choice == "metamask" ? true : false
        this.state.user.userAddress = data.address
        this.state.user.publicKey = data.publicKey
        this.state.user.isLogged = true

        if(choice == "metamask"){
          this.state.signer.isMetamask = true
        }else{
          this.state.signer.isMetamask = false
        }
        this.state.signingChoice.error = null
        return {result: "ok"}
      }else{
        this.state.signingChoice.error = data.error
        return {result: data.error}
      }
      
    },
    signerInvoke: async function(context, data) {
      if(!this.state.user.isLogged){
        this.state.signingChoice.show = true
        return
      }
      let res
      let _this = this;

      // check metamask require login first
      await this.dispatch('loginMetamask', context)
        try{
          
          //console.log(JSON.stringify(data, undefined, 4));
          console.log(data)
          res = await SignerInstance.invoke(data).broadcast()
        }catch(error){
          console.log("try ko")
          console.log(error)
          res = { error: error }
        }

      if(!res.error){
        console.log("res")
        console.log(res)
        let idToCheck = res.id ? res.id : res[0].id
        await this.state.nodeInteraction.waitForTx(
          idToCheck, { apiBase: _this.state.config.nodeURL }
        );
        let finalState = await this.state.nodeInteraction.stateChanges(
          idToCheck,  _this.state.config.nodeURL
        );
        let user = res && res.sender ? res.sender : null
        console.log("res")
        console.log(res)
        res = { data: finalState, user:  user, id: idToCheck};
      }

      return res
    },
    signerTransfer: async function(context, data) {
      let _this = this;
      let res
      await this.dispatch('loginMetamask', context)
        try{
          console.log("data")
          console.log(data)
          res = await  SignerInstance.transfer(data.data).broadcast()
          console.log("res")
          console.log(res)
        }catch(err){
          
          res = {error: err}
        }
      //}

      if(!res.error){
        console.log("start nodeInteraction")
        let idToCheck = res.id ? res.id : res[0].id

        idToCheck = validators.isBase58(idToCheck) ? idToCheck : ethTxId2waves(idToCheck)
        await this.state.nodeInteraction.waitForTx(
          idToCheck,
          { apiBase: _this.state.config.nodeURL }
        );
        res = { data: data.data };
        console.log("nodeInteraction done")
      }
      return res
    },
  },
});