<template>
  <div id="wrapper">
    <MsgLayer class="pos-over"/> 
    <LoginWidget class="pos-over" />
    <SigningChoice class="pos-over" />
    <Front class="mb-5" msg="Welcome to SIGN Events App"/>
    <footer class="mt-auto">Copyright 2022 Signature Chain</footer>
  </div>
</template>

<script>
import Front from './components/Front.vue'
import SigningChoice from './components/elements/SigningChoice.vue'
import LoginWidget from './components/elements/LoginWidget.vue'
import MsgLayer from './components/elements/MsgLayer.vue'

export default {
  name: 'App',
  components: {
    Front,
    SigningChoice,
    LoginWidget,
    MsgLayer
  },
  created(){
    this.$store.dispatch("getPriceInUsd")
  }
}
</script>

<style lang="scss">
body, html{
  background-color: black;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 300;
}
.pos-over{
  position: relative;
  z-index: 50;
}
.bg{
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position-x: center!important;
}
#app {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 1.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: black;
  font-weight: 300;
  color: white;
  .page{
    text-align: left;
    h3{
      text-align: left;
      color: white;
      letter-spacing:10px;
      font-size: 40px;
      font-weight: 500;
    }
  }
  .logo{
    max-width: 180px;
    transition: all .4s ease;
    &:hover{
      opacity: 0.8;
    }
  }
  ul{
    padding-left: 0;
    li{
      list-style: none;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
.detail{
  .date{
    font-weight: bold;
    margin-bottom: 10px;
  }
  .desc{
    //margin-bottom: 20px;
    letter-spacing: initial;
    font-weight: 300;
  }
  h2{
    font-weight: bold;
    font-size: 40px;
    margin-top: 25px;
  }
}
  .btn-primary, .btn-danger{
    background-color: #3bedef;
    border-color: #3bedef;
    color: #020102;
    text-transform: uppercase;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    transition: all .4s ease;
    font-weight: 500;
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 16px;
    &:hover{
      background-color: #1E1A1A;
      border-color: #1E1A1A;
      color: white
    }
  }
  .btn-primary{
    background-color: #3bedef;
    border-color: #3bedef;
    &.big{
      padding: 8px 30px;
      font-size: 22px;
    }
  }
  .btn-danger{
    background-color: transparent;
    border-color: #999;
    color: #999;
  }

  .mention{
    font-size: 14px;
    font-style: italic;
  }
  .mention.warning{
    display: inline-block;
    cursor: pointer;
  }
  .organizer{
    font-size: 16px;
    a{
      text-decoration: none;
      color: white;
      display: flex;
      align-items: center;
      padding-top: 2px;
      span{
        color: #26c7d2;
      }
      img{
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
  .actions{
    //padding-top: 20px;
  }
  .tickets{
    .actions{
      padding-top: 0;
    }
  }
  #response, .response{
    margin-bottom: 40px;
    font-weight: 400;
  }
  #response .wrap, .response .wrap {
    height: auto;
    min-height: auto;
    padding: 15px;
    opacity: 1;
    color: black;
    background-color: #3bedef;
    border-radius: 10px;
  }

  #response.error .wrap, .response.error .wrap {
    background-color: #e54a4a;
  }
  .events{
    .event{
      margin-bottom: 35px;
      background-color: white;
      color: #2c3e50;
      border-radius: 20px;
      box-shadow: 0px 0px 24px 4px rgba(0,0,0,0.30);
      &.noborder{
        border: none;
      }
    }
  }
  .event {
  
    text-align: left;
    
    .flyer{
      //height: 100%;
      max-height: 360px; 
      overflow: hidden;
      padding: 0;
      border-radius: 20px 0 0 20px;
      background-color: black;
      display: flex;
      min-height: 200px;

      /* img{
        width: 100%;
        height: 100%; 
        object-fit: cover;
      } */
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 25px 50px;
      letter-spacing: initial;
      h2{
        font-size: 40px;
        font-weight: 700;
      }
      .desc{
        font-weight: 300;
        font-size: 16px;
      }
      .date{
        font-weight: bold;
        margin-bottom: 10px;
      }
      .tickets_details{
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        font-weight: 300;
        .tickets_max_available, .tickets_sold, .tickets_price, .tickets_type, .tickets_loction{
          margin-right: 20px;
          //font-style: italic;
          span{
            font-weight: bold;
          }
        }
        .tickets_sold{
          font-size: 16px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .marketplace{
    .event{
      .flyer{
        height: 100%;
      }
    }
  }
  #wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .loadingText {
    font-size: 16px;
    text-align: center;
    padding: 10px;
    animation: blink 2s linear infinite;
    font-style: italic;
    letter-spacing: 0.5px;
  }
  .copied{
    &.show{
      position: absolute;
      bottom: -9px;
      left: -0px;
      font-size: 15px;
      background-color: #3ca5a0;
      color: white;

      padding: 5px 15px;
      border-radius: 50px;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
  @keyframes blink {
    0% {
      opacity: .4;
    }
    25% {
      opacity: .7;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: .7;
    }
    100% {
      opacity: .4;
    }
  }

  /* .links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;

    li {
      margin-right: 0px;
      color: white;
      transition: all .4s ease;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 50px;
      margin-right: 10px;
      background-color: white;
      border: solid 1px #9A0E88;
      display: flex;
      margin-bottom: 10px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 147%;
      text-align: center;
      letter-spacing: -0.015em;
      color: #131111;
      

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 24px;
        margin-right: 10px;
      }

      a {
        color: white;
        text-decoration: none;
        padding: 7px 10px;
      }

      &:hover {
        background-color: #6ED6DD;
        color: white;
        border: solid 1px black;
        
        i {
          color: white;
        }

        a {
          color: white;
        }
      }
    }
  } */
  .cta{
    background: rgb(252,184,255);
    background: linear-gradient(114deg, rgba(252,184,255,1) 0%, rgba(254,231,255,1) 48%, rgba(110,214,221,1) 100%);
    border-radius: 250px;
    color: #020102;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    padding: 6px 25px;
    transition: all .4s ease;
    letter-spacing: initial;
    text-decoration: none;
    display: inline-block;
    border: 0;
    &:hover{
      opacity:0.8;
    }
  }
  .cta.offer{
    background: linear-gradient(114deg, rgba(151,224,131,1) 0%, rgba(243,255,179,1) 48%, rgba(243,255,179,1) 100%);
  }
  .ticket_used{
    /* position: absolute;
    z-index: 2; */
    background-color: #5dbcbc;
    border-radius: 5px;
    color: white;
    padding: 3px 10px;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 18px;
    cursor: pointer;
    &.used{
      background-color: #bd4d72;
    }
  }
  /* .links li.buyBtn{
    border: 0;
    background-color: #6ED6DD;
    &:hover{
      background-color: #065359;
    }
  }
  .links li.bidBtn{
    background-color: #0085FF;
    color: white; 
    border: 0;
    &:hover{
      background-color: #065359;
    }
    a{
      color: white;
      
    }
  } */
}
.fa-xmark{
  cursor: pointer;
}
.tooltip{
  color: black;
  .tooltip-arrow{
    &:before{
      border-bottom-color: white!important;
    }
  }
  .tooltip-inner{
    line-height: 18px;
    padding-bottom: 5px;
    background-color: white;
    color: black!important;
  }
}
#salePopin, #bidPopin, #acceptBidPopin{
  letter-spacing: 0.5px;
  background-color: rgba(3, 43, 40, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //height: calc(100% - 35px);
  height: 100%;
  z-index: 945;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper{
    color: black;
    width: 100%;
    max-width: 768px;
    background-color: white;
    padding: 15px;
  }
}
#bidPopin, #acceptBidPopin{
  .wrapper{
    max-width: 360px;
  }
}
.px-6{
  padding: 0 120px!important;
}
.mw-70{
  max-width: 85%;
  @media(max-width: 1600px){
    max-width: 100%;
  }
}
.switchForSale{
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    @media(max-width: 768px){
      justify-content: center;
    }
    .bold{
      font-weight: 600;
    }
    .fa-toggle-on {
      color: #3bedef!important;
      transform: rotate(0deg);
      font-size: 23px;
      &.on{
        transform: rotate(180deg); 
      }
    }
    .count{
        font-size: 18px;
        font-weight: 400;
        color: #767676;
      }
  }
  .v3dp__popout{
    bottom: 35px!important;
  }
  footer{
    color: white;
    z-index: 1;
    position: relative;
    font-size: 13px;
    font-weight: 300;
    padding: 25px 0;
    background-color: rgba(0,0,0,0.7);
    text-align: center;
  }
  label{
    margin-bottom: 8px;
    padding-left: 8px;
    color: rgb(224, 224, 224);
    letter-spacing: 1px;
    font-size: 16px!important;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .mandatory{
    font-size: 14px;
    color: rgb(224, 224, 224);
  }

  @media(max-width: 1200px){
    .container.detail {
      max-width: 1100px;
    }
  }
  @media(max-width: 992px){

    #app{
      .ticket{
        flex-direction: column;
        .title_desc, .tickets_details{
          width: 100%;
        }
        .title_desc{
          text-align: center;
          margin-bottom: 10px;
        }
        .tickets_details{
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          margin-top: 10px;
        }
        .actions{
          width: 100%;
        }
      }
      .event{
        .flyer{
          border-radius: 20px 20px 0 0;
        }
        .content{
          padding: 15px 20px;
          h2{
            font-size: 25px!important;
          }
          .date{
            font-size: 15px;
          }
          .desc{
            font-size: 15px;
          }
          .organizer{
            display: block;
            width: 100%;
            margin-bottom: 5px;
          }
          .tickets_details{
            .me-2.ms-2{
              display: none;
            }
          }
          .actions{
            text-align: center;
          }
        }
      }
    }
  }
  @media(max-width:768px){
    .tooltip .tooltip-arrow:before{
      display: none!important;
    }
    #app{
      .page{
        h3{
          letter-spacing:5px;
          font-size: 30px;
          text-align: center!important;
        }
        #content_wrapper{
          
          .wrap{
            margin-top: 40px;
            text-align: center;
            p{
              font-size: 18px;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
</style>
