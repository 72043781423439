<template>
  <div id="admin-manage">
    <div class="container">
      <div class="row">
        <div class="bg"></div>
        <div class="content_wrapper">
          <Transition name="fade">
            <app-create-event :show="showCreatePopin" :closeCreatePopin="closeCreatePopin" :eventToEdit="eventToEdit"/>
          </Transition>
          <div class="container mt-3 mb-3 mt-lg-4 mb-lg-4 d-flex justify-content-between align-items-center m-column">
            <h3 class="text-left">YOUR EVENTS</h3>
            <ul class="text-center">
              <li @click="showCreatePopin = true" class="newEvent d-inline-block btn btn-primary mt-3 mb-3">
                <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon> New event
              </li>
            </ul>
          </div>
          <app-events-list @editEvent="editEvent" :showCreatePopin="showCreatePopin"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateEvent from '@/components/admin/elements/NewEvent.vue' 
import EventsList from '@/components/admin/elements/EventsList.vue' 
export default {
  name: 'Manage-page',

  data: function(){
    return {
      showCreatePopin: false,
      eventToEdit: null
    }
  },
  components: {
    'app-create-event': CreateEvent,
    'app-events-list': EventsList
  },
  methods:{
    closeCreatePopin: function(){
      this.showCreatePopin = false
    },
    editEvent: function(eventData){
      this.eventToEdit = eventData
      this.showCreatePopin = true
      
    }
  },
  watch: {
    showCreatePopin: function(show){
      if(!show){
        this.eventToEdit = null
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .newEvent{
    cursor: pointer;
  }
  .bg{
    background: url("@/assets/images/bg-blue.jpg");
  }
  @media(max-width:768px){
    .m-column{
      flex-direction: column;
      ul{
        margin-bottom: 0;
      }
    }
  }
</style>
