<template>
  <transition name="fade">
    <div id="acceptBidPopin" v-show="showbidData" ref="acceptBidPopin">
      <div class="wrapper">
        <div class="form-group mb-0">
          <div class="row">
            <div class="col-lg-12 mt-3 text-center">
              <h4 class="uppercase">{{ bidData.type }}</h4>
              <p>OFFER of {{ bidData.price }}</p>
            </div>
            <div class="col-12">
              <div :class="{error: bid.haveError}" v-show="bid.errorMessage!=''" class="response container w-100 mx-auto text-center mt-3">
                <div class="wrap">{{bid.errorMessage}}</div>
              </div>
              <button class="btn btn-primary bigger mb-0 mt-3 mx-auto d-block uppercase" @click.prevent="processAction"> {{ bidData.type }} THE OFFER</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    </transition>
</template>
<script>
import globalMixin from '@/mixins/global.js';

export default {
  props: ['bidData', 'showbidData', 'hideBidData', "updateOfferList"],
  mixins: [globalMixin],
  data: function(){
    return {
      bid: {
        haveError: false,
        errorMessage: ""
      },
      finalPrice: 0
    }
  },
/*   watch: {
    showbidData: function(){
      this.showbidData ? document.getElementById("content").style.overflowY="hidden" : document.getElementById("content").style.overflowY="auto"
    }
  }, */
  created(){
    this.init()
  },
  mounted(){
    document.getElementById('acceptBidPopin').addEventListener('mousedown', this.detectClickOutOfBidPopin);
  },
  beforeUnmount() {
    if(document.getElementById('acceptBidPopin')){
      document.getElementById('acceptBidPopin').removeEventListener('mousedown', this.detectClickOutOfBidPopin);
    }
  },
  methods: {
    init: function(){
      console.log("bidData")
      console.log(this.bidData)
      this.calculatePriceAndTxFee()
    },
    calculatePriceAndTxFee: function(){
      this.finalPrice = this.bidData.basePrice * 10 ** 8
    },
    processAction: function(){

        this.$store.state.alert=true
        this.$store.state.alertMessage='Processing the offer, Please wait...'
        this.calculatePriceAndTxFee()
        let _this = this;

        let functionToCall, dataTx, callArgs, successMessage


          callArgs = [
              { type:"string", value: _this.bidData.nftId },
              { type:"string", value: _this.bidData.bidId },
              { type:"string", value: _this.bidData.bidOwner }
          ]
          
          switch(this.bidData.type){
            case "accept":
            functionToCall = "acceptOffer"
            break
            case "cancel":
            functionToCall = "cancelOffer"
            callArgs.push({ type:"string", value: _this.bidData.saleOwner })
            callArgs.push({ type:"string", value: _this.bidData.ticketId })
            break
            default:
            functionToCall = null
          }

          dataTx = {
            dApp: this.$store.state.config.dapp_addr_secondary,
                call: {
                    function: functionToCall,
                    args: callArgs
                }
          }
          successMessage = functionToCall == "acceptOffer" ? 'Offer accepted successfully!' : "Offer cancelled"
        

        this.$store.dispatch("signerInvoke", dataTx).then( res => { 
            this.$store.state.alert=false
            this.$store.state.alertMessage=''
            console.log("res in bid action")
            console.log(res)
            if(res.error){
              // this is because you may have 5 pending offer but once you accept one, you cannot accept more unless someone set it back for sale.
              // would take too many ressource to check if each offer if for an open sale
              let addToError = res.error.message.includes("NFT not found") ? " - You may have already accepted an offfer for this sale. You can decline the offer or just ignore it" : ""
              this.bid.errorMessage = res.error.message ? res.error.message+addToError : res.error
              this.bid.haveError = true
            }else{
              // display confirmation message of success
              _this.$store.state.alert=false
              _this.$store.state.alertMessage=''
              _this.bid.errorMessage = ''
              _this.bid.haveError = false

              _this.$emit("updateOfferList")
              _this.$emit("hideBidData", {haveError: false, responseMessage: successMessage})
            }
        }) 
      
    },
    detectClickOutOfBidPopin: function(e){   
      if(this.showbidData){
        if (!document.getElementById('acceptBidPopin').querySelector(".wrapper").contains(e.target)){
          this.$emit("hideBidData", {haveError: false, responseMessage: ''})
          this.bid.errorMessage = ''
          this.bid.haveError = false
         //document.getElementById("content").style.overflowY="auto"
        }
      }
    }
  }
}
</script>
<style lang="scss">
.uppercase{
  text-transform: uppercase;
}
</style>