<template>
  <div class="col-lg-3 flyer">
    <router-link :to="`event/${event.txid}`">
      <!-- <img :src="getIpfsLink(event.event_displayCid)" class="d-block -mg-fluid"/> -->
      <imageLoader 
        :source="event.event_displayCid ? getIpfsLink(event.event_displayCid) : emptySrc" 
        :imgix="$parent.imgix && $parent.imgix[keyIndex].url ? $parent.imgix[keyIndex].url : event.event_displayCid" 
        :altname="event.event_name" 
      />
    </router-link>
  </div>
  <div class="col-lg-9 content">
    <div v-if="event.event_canceled">CANCELED <p>{{event.event_canceledReason}}</p></div>
    <h2>{{event.event_name}}</h2>
    <div class="date">Event date: {{ event.event_date }}</div>
    <div class="desc">{{ trim(event.event_desc, 150)}}</div>
    
    <div class="tickets_details">
      <Creator :user_addr="event.user_addr" />
      <div class="me-2 ms-2">-</div>
      <div class="tickets_sold">Sold: <span>{{event.event_totalIssued}}</span></div>
      <div class="tickets_price">Starting price: <span>{{setStartingPrice}} SIGN</span></div>
      
    </div>

    <div class="actions mt-lg-4 mt-3 w-100">
      <router-link :to="`/event/${event.txid}`" class="cta me-lg-3 mx-auto">SEE ALL TICKETS</router-link>
    </div>
    
  </div>
</template>

<script>
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
import imageLoader from '@/components/elements/ImageItem.vue' 
import Creator from '@/components/admin/elements/EventItemCreator.vue' 
export default {
  name: 'event-page-item-front',
  mixins: [Global],
  props:['event', 'keyIndex'],

  data: function(){
    return {
      cancelReason: '',
      startingPrice: 0
    }
  },
  components:{
    Creator,
    imageLoader
  },
  created(){
    this.getStartingPrice(this.event.txid)
  },
  computed: {
    setStartingPrice(){
      return this.numberWithSpaces(this.startingPrice / Math.pow(10,8))
    }
  },
  methods: {
    getTicketsLowerPrice: async function(obj){
      let res =  await Math.min(...obj.map(item => item.value));
      return res == Infinity ? 'N/A' : res
    },
    getStartingPrice: async function(eventId){
      let regex = encodeUrl("^ticket_price_"+eventId+"_(.*)")
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_events}?matches=${regex}`)
      getList = await getList.json() 
      this.startingPrice = await this.getTicketsLowerPrice(getList)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.addeddate{
  font-size: 13px;
  color: #999;
}
</style>
