const axios = require('axios');

async function upload(file, callback) {
  console.log("file in ipfs upload")
  console.log(file)
  let formData = new FormData()
  formData.append("file", file, file.name)
  console.log("file in ipfs upload as formData")
  console.log(formData)
  try{
    let add = await axios.post(process.env.VUE_APP_signArtApiURL+"/ipfs", formData, {
      maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
      onUploadProgress: function(progressEvent) {
        console.log(progressEvent.loaded)
        let addingToIpfs = false
        if(file.size <= progressEvent.loaded){
          console.log("Adding your file to IPFS")
          addingToIpfs = true
        }else{
          addingToIpfs = false 
        }
        callback({loaded: progressEvent.loaded , total: file.size, addingToIpfs: addingToIpfs})
        
      },
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    return { cid: { string: add.data.result } };
  }catch(err){
    console.log(err)
    return err
  }

}

module.exports = {
  upload
};