<template>
  <div class="page marketplace events">
    <div class="container-fluid">
      <div class="row">
        <div class="bg"></div>
        <div class="content_wrapper">
          <div class="container">
            <h3 class="mt-4">MARKETPLACE</h3>
          </div>
          <div class="loadingText" v-if="isLoadingTickets">
            Loading tickets list...
          </div>
          <div class="text-center mt-4" v-if="!allTickets.length && !isLoadingTickets">
            No tickets found.
          </div>
          <div class="container-fluid mw-70">
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-6 px-4 mb-4" v-for="(ticket, key) in allTickets" :key="ticket.txid" :data-index="key">
                <EventItemSecondary :ticket="ticket"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import encodeUrl from 'encodeurl';
import Global from '@/mixins/global.js';
import EventItemSecondary from '@/components/marketplace/elements/EventItemSecondary.vue' 
export default {
  name: 'marketplace-page',
  mixins: [Global],
  data: function(){
    return {
      allTickets: [],
      isLoadingTickets: false
    }
  },
  components: {
    EventItemSecondary
  },
  created(){
    this.getSecondaryOpenTickets()
  },
  methods: {
    getSecondaryOpenTickets: async function(address = ''){
      this.isLoadingTickets = true
      let regex = encodeUrl("^sale2nd_info_(.*)_OPEN") 
      if(address!=''){
        regex = encodeUrl("^sale2nd_info_"+address+"(.*)_OPEN")
      }
      let getList = await fetch(`${this.$store.state.config.nodeURL}/addresses/data/${this.$store.state.config.dapp_addr_secondary}?matches=${regex}`)
      getList = await getList.json()
      console.log("getList")
      console.log(getList)
      let orderedList = this.getOrderedSecondaryTickets(getList)
      console.log("orderedList")
      console.log(orderedList)

      this.allTickets = orderedList
      this.isLoadingTickets = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3{
    margin-bottom: 40px!important;
  }
  .marketplace{
    padding: 0 40px;
  }
  .bg{
    background: url("@/assets/images/bg-fushya.jpg");
  }
  @media(max-width: 992px){
    h3{
      margin-bottom: 30px!important;
    }
    .marketplace{
      padding: 0 0px;
    }
  }
</style>
