<template>

  <div class="w-100 ticket">
    
    <div class="title_desc">
      <h4>{{ticket.ticket_name}}</h4>
      <div class="desc">{{ ticket.ticket_desc }}</div>
      <div class="mention warning" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="'Resell allowed after: '+setDate(ticket.ticket_resellLimitation)" v-if="ticket.ticket_resellLimitation>0 && ticket.ticket_resellLimitation >  new Date().getTime()"><font-awesome-icon icon="fa-solid fa-triangle-exclamation" /> Resell limited</div>
    </div>
    
    <div class="tickets_details">
      <div class="tickets_sold">Sold: <span>{{ticket.ticket_totalIssued}} of {{ ticket.ticket_maxAvailable }}</span></div>
      <div class="tickets_price">Price: <span>{{ numberWithSpaces(base10ToDecimal(ticket.ticket_price, 8)) }} SIGN</span></div>
      <div class="tickets_use">Max use: <span>{{ ticket.ticket_maxUse }}</span></div>
    </div>
    
    <div class="actions">
      <button class="btn btn-primary" @click="editParentTicket()">Edit Ticket</button>
      <div class="pauseUnpause d-flex justify-content-center">
        <div class="pause" @click="setOnsaleTicket(false)" v-if="ticket.ticket_onSale">Pause</div>
        <div class="pause" @click="setOnsaleTicket(true)" v-else>Resume</div>
        <div class="delete ms-2" v-if="ticket.ticket_totalIssued == 0 || !ticket.ticket_totalIssued" @click="deleteTicket">Delete</div>
      </div>
    </div>
    
  </div>
  <div :class="{error: haveError}" v-show="responseMessage!=''" class="response col-xl-10 mx-auto text-center mt-3 mb-3">
    <div class="wrap">{{responseMessage}}</div>
  </div>
</template>

<script>
/* import encodeUrl from 'encodeurl'; */
import Global from '@/mixins/global.js';
import date from 'date-and-time'
export default {
  name: 'Manage-page-item-ticket',
  mixins: [Global],
  props:['ticket'],
  emits: ["editTicket", "getTicketsByEventId"],
  data: function(){
    return {
      haveError: false,
      responseMessage: ''
    }
  },
  mounted(){
    console.log(this.ticket)
  },
  methods: {
    editParentTicket: function(){
      console.log("hihihi")
      this.$emit("editTicket", this.ticket);
    },
    setDate: function(timestamp){
      return date.format(new Date(timestamp),'YYYY/MM/DD HH:mm:ss')
    },
    deleteTicket: async function(){
      console.log("processing delete...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: "deleteTicket",
            args:[
              { type:"string", value: this.ticket.txid },
              { type:"string", value: this.$route.params.eventId }
            ]
        }
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Ticket successfully deleted!'
            setTimeout(function(){
              _this.responseMessage = ''
              _this.$emit('getTicketsByEventId', _this.$route.params.eventId);
              // parent reload ticket list
            }, 3000)
          }
      })
    },
    setOnsaleTicket: async function(status){
      console.log("processing pause...")
      this.haveError = false
      this.responseMessage = ''
      let _this = this

      this.$store.state.alertMessage='Sending your data to the Waves Blockchain...'

      const dataTx = {
        dApp: this.$store.state.config.dapp_addr_events,
        call: {
            function: "pauseTicketSale",
            args:[
              
              { type:"string", value: this.$route.params.eventId },
              { type:"string", value: this.ticket.txid },
              { type:"boolean", value: status }
            ]
        }
      }

      this.$store.dispatch("signerInvoke", dataTx).then(res => { 
          this.$store.state.alert=false
          this.$store.state.alertMessage=''
          if(res.error){
            // siplay error
            this.throwError(res.error.message ? res.error.message : res.error)
          }else{
            // display confirmation message of success
            this.haveError = false
            this.responseMessage = 'Ticket status successfully updated!'
            setTimeout(function(){
              _this.responseMessage = ''
              _this.$emit('getTicketsByEventId', _this.$route.params.eventId);
              // parent reload ticket list
            }, 3000)
          }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.ticket{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 15px;
  letter-spacing: initial;
  font-weight: 300;
  .title_desc{
    width: 45%;
    padding-right: 10px;
    
    h4{
      margin-bottom: 5px;
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .tickets_details{
    //width: 35%;
    span{
      font-weight: 500;
    }
    
  }
  .actions{
    //width: 20%;
    margin-left: auto;
  }
}
.actions{
  min-width: 120px;
  text-align: center;
  .delete, .pause{
    color: #ffffff;
    text-decoration: underline;
    font-size: 13px;
    margin-top: 3px;
    cursor: pointer;
    &:hover{
      text-decoration: none;
    }
  }
}

</style>
